import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
   GET_SUBSCRIBERS,
   GET_SUBSCRIBERS_SEARCH,
} from "./actionTypes"
import {
   getSubscribersSuccess,
   getSubscribersFail,
   isLoadingSubscribers,
   isLoadedSubscribers
} from "./actions"

import {
   findSubscribersByFilter
} from '../../api/subscribers';

function* fetchSubscriberSearch({ search }) {
   try {
      yield put(isLoadingSubscribers());
      const response = yield call(findSubscribersByFilter, search);
      yield put(getSubscribersSuccess(response.data.orders));
      yield put(isLoadedSubscribers());
   } catch (error) {
      yield put(getSubscribersFail(error));
   }
}

function* subscribersSaga() {
   yield takeEvery(GET_SUBSCRIBERS, fetchSubscriberSearch);
   yield takeEvery(GET_SUBSCRIBERS_SEARCH, fetchSubscriberSearch);
}

export default subscribersSaga
