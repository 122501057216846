import React, { useEffect, useState } from "react";
import {
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";
import FormResponderDenuncia from "./_components/_formResponder";
import { getTopicSingle } from "api/community";

function ModalAdd({ modal, toggle, isEdit, denunciateItem }) {

   const [entity, setEntity] = useState(null);

   const fetchDenunciateItem = async () => {
      const respData = await getTopicSingle({_idTopic: denunciateItem.id_entity});
      console.log('respData.data.topics', respData.data.topics);
      setEntity(respData.data.topics)
   }

   useEffect(()=>{
      console.log('denunciateItem',denunciateItem);
      if(denunciateItem) {
         fetchDenunciateItem()
      }
   },[denunciateItem]);

   return (
      <Modal
         size="lg" 
         style={{maxWidth: '700px', width: '100%'}}
         isOpen={modal} 
         toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            Responder
         </ModalHeader>
         <ModalBody>
            <FormResponderDenuncia 
               isEdit={isEdit} 
               denunciateItem={denunciateItem} 
               toggle={toggle} 
               entity={entity} 
            />
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   denunciateItem: PropTypes.object,
}


export default ModalAdd;

