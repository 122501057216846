import React, { useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { validateEmail } from "utils";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

import { useDispatch } from "react-redux"

import {
   getDenunciateSearch as onGetDenunciateSearch,
} from "store/community/actions";

const options = [
   {
      label: 'Não Respondido',
      value: 0,
   },
   {
      label: 'Aprovado',
      value: 1,
   },
   {
      label: 'Reprovado',
      value: 2,
   }
]

function ModalFiltro({ modal, toggle, isEdit }) {
   const dispatch = useDispatch();

   const [erros, setErros] = useState(null);
   const [selectedValue, setSelectedValue] = useState(null);

   function handleMulti(selected) {
      setSelectedValue(selected);
   }

   /**
    * Handling submit customer on customer form
    */
   const handleValidSubmit = (e, values) => {
      // console.log('values', values);
      setErros(null);
      const objToSend = {};
      if (selectedValue) {
         objToSend.status = selectedValue.value;

      } else if (values.from !== "" && values.to !== "") {

         objToSend.datas = {
            from: values.from,
            to: values.to
         }
      }

      if (Object.keys(objToSend).length === 0) {
         setErros("Preencha algum tipo de filtro.");
      }

      dispatch(onGetDenunciateSearch(objToSend))
      toggle()
   }

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            Filtrar Denúncias
         </ModalHeader>
         <ModalBody>
            <AvForm
               onValidSubmit={handleValidSubmit}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="form-group">
                        <div className="mb-3 templating-select select2-container">
                           <label htmlFor="control-label">Selecione o status...</label>
                           <Select
                              value={selectedValue}
                              isMulti={false}
                              onChange={(event) => handleMulti(event)}
                              options={options}
                              classNamePrefix="select2-selection"
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                           />
                        </div>
                     </div>

                     <div className="mt-4">
                        <h6>ou por data de criação</h6>
                        <div className="d-flex justify-content-between mt-3">
                           <div className="mb-3 w-75" style={{ marginRight: 20 }}>
                              <AvField
                                 name="from"
                                 label="data inicial"
                                 type="date"
                                 errorMessage="data inicial inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value=""
                              />
                           </div>

                           <div className="mb-3 w-75">
                              <AvField
                                 name="to"
                                 label="data final"
                                 type="date"
                                 errorMessage="data final inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value={""}
                              />
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     {erros && <div className="alert alert-danger">{erros}</div>}
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Procurar
                        </button>
                     </div>
                  </Col>
               </Row>
            </AvForm>
         </ModalBody>
      </Modal>
   );
}

ModalFiltro.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
}


export default ModalFiltro;