import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
   const ref = useRef();
   // Use ComponentDidMount and ComponentDidUpdate method symultaniously
   useEffect(() => {
      const pathName = props.location.pathname

      const initMenu = () => {
         new MetisMenu("#side-menu")
         let matchingMenuItem = null
         const ul = document.getElementById("side-menu")
         const items = ul.getElementsByTagName("a")
         for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
               matchingMenuItem = items[i]
               break
            }
         }
         if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
         }
      }
      initMenu()
   }, [props.location.pathname])

   useEffect(() => {
      ref.current.recalculate()
   })

   function scrollElement(item) {
      if (item) {
         const currentPosition = item.offsetTop
         if (currentPosition > window.innerHeight) {
            ref.current.getScrollElement().scrollTop = currentPosition - 300
         }
      }
   }

   function activateParentDropdown(item) {
      item.classList.add("active")
      const parent = item.parentElement
      const parent2El = parent.childNodes[1]
      if (parent2El && parent2El.id !== "side-menu") {
         parent2El.classList.add("mm-show")
      }

      if (parent) {
         parent.classList.add("mm-active")
         const parent2 = parent.parentElement

         if (parent2) {
            parent2.classList.add("mm-show") // ul tag

            const parent3 = parent2.parentElement // li tag

            if (parent3) {
               parent3.classList.add("mm-active") // li
               parent3.childNodes[0].classList.add("mm-active") //a
               const parent4 = parent3.parentElement // ul
               if (parent4) {
                  parent4.classList.add("mm-show") // ul
                  const parent5 = parent4.parentElement
                  if (parent5) {
                     parent5.classList.add("mm-show") // li
                     parent5.childNodes[0].classList.add("mm-active") // a tag
                  }
               }
            }
         }
         scrollElement(item);
         return false
      }
      scrollElement(item);
      return false
   }

   return (
      <React.Fragment>
         <SimpleBar className="h-100" ref={ref}>
            <div id="sidebar-menu">
               <ul className="metismenu list-unstyled" id="side-menu">
                  <li className="menu-title">{props.t("Menu")} </li>
                  <li>
                     <Link to="/dashboard" className="">
                        <i className="bx bx-line-chart"></i>
                        <span>{props.t("Dashboard")}</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/clientes" className="">
                     <i className='bx bxs-user-account'></i>
                        <span>Clientes</span>
                     </Link>
                  </li>
                  <li className="menu-title">Treinos</li>
                  <li>
                     <Link to="/treinos" className="">
                     <i className='bx bx-run'></i>
                        <span>Treinos</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/treinos/exercicios" className="">
                        <i className="bx bx-body"></i>
                        <span>Exercícios</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/treinos/equipamentos" className="">
                        <i className="bx bx-dumbbell"></i>
                        <span>Equipamentos</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/treinos/locais" className="">
                        <i className="bx bx-home"></i>
                        <span>Locais</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/categorias" className="">
                     <i className='bx bx-plus-medical' ></i>
                        <span>Categorias</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/trainers" className="">
                        <i className="bx bx-user-plus"></i>
                        <span>Treinadoras</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/treinos/modalidaes" className="">
                        <i className="bx bx-walk"></i>
                        <span>Modalidades</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/destaques" className="">
                        <i className="bx bxs-star"></i>
                        <span>Destaques</span>
                     </Link>
                  </li>
                  <li className="menu-title">Receitas</li>
                  <li>
                     <Link to="/receitas" className="">
                        <i className="bx bx-food-menu"></i>
                        <span>Receitas</span>
                     </Link>
                  </li> 
                  <li className="menu-title">Blog</li>
                  <li>
                     <Link to="/blog/posts" className="">
                        <i className="bx bx-news"></i>
                        <span>Posts</span>
                     </Link>
                  </li>
                  <li className="menu-title">Comunidade</li>
                  <li>
                     <Link to="/comunidade/denuncias" className="">
                        <i className='bx bx-spreadsheet'></i>
                        <span>Denúncias</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/comunidade/comments" className="">
                        <i className='bx bx-spreadsheet'></i>
                        <span>Comentários</span>
                     </Link>
                  </li>
                  <li>
                     <Link to="/comunidade/forum" className="">
                        <i className='bx bx-spreadsheet'></i>
                        <span>Fórum</span>
                     </Link>
                  </li>
                  <li className="menu-title">Relatórios</li>
                  <li>
                     <Link to="/reports/subscribers" className="">
                        <i className='bx bx-spreadsheet'></i>
                        <span>Assinantes</span>
                     </Link>
                  </li> 
                  <li>
                     <Link to="/reports/nosubscribers" className="">
                        <i className='bx bx-spreadsheet'></i>
                        <span>Não Assinantes</span>
                     </Link>
                  </li> 
               </ul>
            </div>
         </SimpleBar>
      </React.Fragment>
   )
}

SidebarContent.propTypes = {
   location: PropTypes.object,
   t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
 