import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Loading from "components/Loading";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector } from "react-redux";
import SuccessMessage from "components/SuccessMessage";

import {
    getRecipes as onGetRecipes,
} from "store/recipes/actions"

import { useDispatch } from "react-redux";
import { setupCategories } from "api/community";

const animatedComponents = makeAnimated();

const options = [
    {
        options: []
    }
];

const optionsDate = [
    {
        options: []
    }
];

const FormCategorias = ({ isEdit, topicItem, toggle }) => {
    const dispatch = useDispatch();

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const { categories } = useSelector(state => ({
        categories: state.categories.categories,
    }));

    useEffect(() => {
        if (categories) {
            categories.map(item => {
                if (item.type === 'forum') {
                    options[0].options.push(
                        {
                            label: item.name,
                            value: item._id,
                        }
                    );
                }
            });
        }
    }, [categories]);

    useEffect(() => {
        if (topicItem) {
            const arrCategorias = topicItem.categories.filter(item => item.type == "forum" && item).map(item => {
                return (
                    {
                        label: item.name,
                        value: item._id
                    }
                )
            });
            setSelectedValue(arrCategorias);
        }
    }, [topicItem]);


    const handleValidCustomerSubmit = async (data) => {
        setSuccessMessage(false);
        setIsLoading(true);

        const payload = { _id: topicItem._id };

        const arrCategoriesValue = selectedValue.map((item)=>item.value)
        if (selectedValue) {
            payload.categories = arrCategoriesValue;
        }

        await setupCategories(payload);
        setSuccessMessage(true);
        dispatch(onGetRecipes());

        setIsLoading(false);
    }

    function handleMulti(selected) {
        setSelectedValue(selected);
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mt-3">
                            <div className="form-group">
                                <div className="mb-3 templating-select select2-container">
                                    <label htmlFor="control-label">Adicione as categorias</label>
                                    <Select
                                        value={selectedValue}
                                        isMulti={true}
                                        onChange={(event) => handleMulti(event)}
                                        options={options}
                                        classNamePrefix="select2-selection"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                    />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormCategorias.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    topicItem: PropTypes.object,
}

export default FormCategorias;