import React, { useEffect, useState, useRef } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux"

import {
   getTopicComments as onGetTopicComments,
   getTopicDetail as onGetTopicDetail,
} from "store/community/actions";
import ReactSelect from "react-select";
import { authorsArray } from "constants/general";
import makeAnimated from 'react-select/animated';
import { addComment, editComment } from "api/community";

const animatedComponents = makeAnimated();

function ModalAddComment({ modal, idTopic, onClose, isEdit }) {
   const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
   const dispatch = useDispatch();
   const editorRef = useRef(null);

   const [selectedAuthor, setSelectedAuthor] = useState([]);
   const [initialTextValue, setInitialTextValue] = useState("");

   const findAuthor = () => {
      const author = authorsArray.filter((item, index) => item.value === isEdit.users_idusers._id)
      return author
   }

   useEffect(() => {
      console.log('isEdit', isEdit);
      const text = isEdit?.comment;

      const authorEdit = isEdit ? findAuthor() : null
      setSelectedAuthor(authorEdit)

      reset({
         text: isEdit ? text : '',
      });

      setInitialTextValue(isEdit ? isEdit.comment : '');

   }, [isEdit]);

   /**
    * Handling submit customer on customer form
    */
   const handleValidCustomerSubmit = async (data) => {
      
      const textFinal = data.text.trim();

      if(!selectedAuthor.length) {
         alert("Escolha um autor")
         return 
      }

      const author = selectedAuthor[0].value;
      const sanitizeText = textFinal.replace(/(<script[^>]+?>|<script>|<\/script>)/img, "");

      if (author && textFinal) {
         const objPost = {
            idUser: author,
            idActivity: idTopic,
            comment: sanitizeText,
            from: 'forum'
         }

         if (isEdit) {
            console.log('passou edit');
            objPost['idComment'] = isEdit._id;
            await editComment(objPost);
         } else {
            await addComment(objPost)
         }

         dispatch(onGetTopicDetail(idTopic));
         onClose();
      }
   }

   return (
      <Modal isOpen={modal}>
         <ModalHeader tag="h4">
            Adicionar Comentário
         </ModalHeader>
         <ModalBody>
            <form
               onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="text" className="">Texto</label>
                           <textarea 
                              {...register("text", { required: true })} 
                              className="is-touched is-pristine av-valid form-control" 
                              required 
                           />
                           {errors.text && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3 templating-select select2-container">
                        <label htmlFor="control-label">De quem será o comentário?</label>
                        <ReactSelect
                           value={selectedAuthor}
                           isMulti={false}
                           onChange={(selected) => setSelectedAuthor([selected])}
                           options={authorsArray}
                           classNamePrefix="select2-selection"
                           closeMenuOnSelect={true}
                           components={animatedComponents}
                        />
                     </div>
                  </Col>
               </Row>
               <Row className="mt-4">
                  <Col className="d-flex justify-content-end">
                     <div className="text-end" style={{ marginRight: '15px' }}>
                        <a
                           className="btn btn-danger"
                           onClick={() => onClose()}
                        >
                           Fechar
                        </a>
                     </div>
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Salvar
                        </button>
                     </div>
                  </Col>
               </Row>
            </form>
         </ModalBody>
      </Modal>
   );
}

ModalAddComment.propTypes = {
   modal: PropTypes.bool,
   idTopic: PropTypes.string,
   onClose: PropTypes.func,
   isEdit: PropTypes.object,
}


export default ModalAddComment;