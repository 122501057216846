import { 
    GET_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAIL,
    ADD_POST,
    ADD_POST_SUCCESS,
    ADD_POST_FAIL,
    UPDATE_POST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAIL,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAIL
} from "./actionTypes";

const INIT_STATE = {
    posts: [],
    error: {}
};

const posts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_POSTS:
            return {
                ...state,
                posts: action.payload
            };

        case GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: action.payload
            }

        case GET_POSTS_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case ADD_POST:
            return {
                ...state,
                posts: [...posts, action.payload]
            };

        case ADD_POST_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case UPDATE_POST:
            return {
                ...state,
                posts: state.posts.map(post => {
                    post.id.toString() === action.payload.id.toString() ? { post, ...action.payload } : post
                })
            };

        case UPDATE_POST_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case DELETE_POST:
            return {
                ...state,
                posts: state.posts.filter(
                    post => post.id.toString() != action.payload.id.toString()
                )
            };

        case DELETE_POST_FAIL:
            return {
                ...state,
                error: action.payload
            };

        default:
            return state;
    }
}

export default posts;