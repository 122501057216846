import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import {
   Card,
   CardBody,
   Col,
   Container,
   Row,
} from "reactstrap"

import {
   getSubscribers as onGetSubscribers,
} from "store/subscribers/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import ModalFiltro from "./_components/ModalFiltro"
import TableComponent from "./_components/TableComponent"
import Loading from "components/Loading"

const Subscribers = props => {
   const dispatch = useDispatch()

   const { subscribers } = useSelector(state => ({
      subscribers: state.subscribers.subscribers
   }))

   const { isLoading } = useSelector(state => ({
      isLoading: state.subscribers.isLoading
   }))

   const [SubscriberList, setSubscriberList] = useState([]);
   const [modalFilter, setModalFilter] = useState(false);
   const [isFilterUsed, setIsFilterUsed] = useState(false);

   //pagination customization
   const pageOptions = {
      sizePerPage: 10,
      totalSize: subscribers && subscribers.length, // replace later with size(orders),
      custom: true,
   }

   const EcommerceCustomerColumns = [
      {
         text: "id",
         dataField: "id",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row._id}</>,
      },
      {
         text: "Usuário",
         dataField: "usuario",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (

            <p className="mb-0">{row.user[0].name}</p>
         ),

      },
      {
         text: "Email",
         dataField: "email",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <p className="mb-0">{row.user[0].email}</p>
         ),
      },
      {
         text: "Status",
         dataField: "status",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <p className="mb-0">{row.status}</p>
         ),
      },
      {
         text: "Pago em",
         dataField: "type",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <p className="mb-0">{row.type}</p>
         ),
      },
      {
         dataField: "assinatura",
         text: "Assinatura",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <p className="mb-0">{handleValidDate(row.payday)}</p>
         ),
      },
      {
         text: "Vencimento",
         dataField: "vencimento",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <p className="mb-0">{handleValidDate(row.expirationDate)}</p>
         ),
      },
      {
         text: "created_at_dt",
         dataField: "created_at",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.payday}</>,
      },
   ]



   const toggle = () => {
      setModalFilter(!modalFilter)
   }

   useEffect(() => {
      if (subscribers && !subscribers.length) {
         dispatch(onGetSubscribers())
      }
   }, [])

   useEffect(() => {
      setSubscriberList(subscribers)
   }, [subscribers])

   const defaultSorted = [
      {
         dataField: "created_at_dt",
         order: "desc",
      },
   ]

   /** set Date formate */
   const handleValidDate = date => {
      const date1 = moment(new Date(date)).format("DD/MM/YYYY")
      return date1
   }

   const onHandleFilterUsed = (stt) => {
      setIsFilterUsed(stt)
   }

   function onHandleCleanFilter() {
      dispatch(onGetSubscribers());
      onHandleFilterUsed(false)
   }

   return (
      <>
         <React.Fragment>
            <div className="page-content">
               <MetaTags>
                  <title>Relatório de Assinantes | Suore</title>
               </MetaTags>
               <Container fluid>
                  {/* <Breadcrumbs title="Suore" breadcrumbItem="Clientes" /> */}
                  <h3>Relatório de Assinantes </h3>
                  <Row>
                     <Col xs="12">
                        <Card>
                           <CardBody>
                              <div className="d-flex align-center justify-content-center">{isLoading && <Loading />}</div>
                              {<TableComponent
                                 EcommerceCustomerColumns={EcommerceCustomerColumns}
                                 pageOptions={pageOptions}
                                 data={subscribers}
                                 toggle={toggle}
                                 defaultSorted={defaultSorted}
                                 filtered={isFilterUsed}
                                 showTotal={1}
                                 showFilter={1}
                                 handleCleanFilter={onHandleCleanFilter}
                              />
                              }
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
         <ModalFiltro modal={modalFilter} toggle={toggle} handleFilterUsed={onHandleFilterUsed} />
      </>
   )
}

Subscribers.propTypes = {
   subscribers: PropTypes.array,
   total: PropTypes.number,
   onGetSubscribers: PropTypes.func,
}

export default Subscribers
