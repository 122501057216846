import {
   GET_SUBSCRIBERS_FAIL,
   GET_SUBSCRIBERS_SUCCESS,
   GET_SUBSCRIBERS_SEARCH,
   GET_SUBSCRIBERS_SEARCH_CLEAN,
   IS_LOADING_SUBSCRIBERS,
   IS_LOADED_SUBSCRIBERS
} from "./actionTypes"

const INIT_STATE = {
   subscribers: [],
   total: 0,
   isLoading: false,
}

const Subscribers = (state = INIT_STATE, action) => {
   switch (action.type) {
      case GET_SUBSCRIBERS_SUCCESS:
         return {
            ...state,
            subscribers: action.payload
         }
      case GET_SUBSCRIBERS_FAIL:
         return {
            ...state,
            error: action.payload,
         }
      case GET_SUBSCRIBERS_SEARCH_CLEAN:
         return {
            ...state,
            subscribeSearch: [],
         }
      case GET_SUBSCRIBERS_SEARCH:
         return {
            ...state,
            subscribers: action.payload,
         }
      case IS_LOADING_SUBSCRIBERS:
         return {
            ...state,
            isLoading: true,
         }
      case IS_LOADED_SUBSCRIBERS:
         return {
            ...state,
            isLoading: false,
         }
      default:
         return state
   }
}
export default Subscribers
