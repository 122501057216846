import React, { useEffect, useState } from "react";
import {
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import PropTypes from "prop-types";
import FormGeneralInfo from "./_components/_formGeneralInfo";
import FormCategorias from "./_components/_formCategorias";
import FormMedia from "./_components/_formMedias";

function ModalAdd({ modal, toggle, isEdit, topicItem }) {
   const [tabActive, setTabActive] = useState(0);

   useEffect(()=>{
      setTabActive(0);
   }, [isEdit, topicItem]);

   return (
      <Modal
         size="lg" 
         style={{maxWidth: '700px', width: '100%'}}
         isOpen={modal} 
         toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? 'Editar' : 'Adicionar'} Tópico
         </ModalHeader>
         {isEdit && <div className="d-flex w-100 mt-4">
            <div className="btn-group mb-3 mx-auto">
               <button type="button" onClick={()=>setTabActive(0)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 0 ? 'active':''}`}>
                  Informações Gerais
               </button>
               <button type="button" onClick={()=>setTabActive(1)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 1 ? 'active':''}`}>
                  Categorias
               </button>           
               <button type="button" onClick={()=>setTabActive(2)}
                  className={`btn btn-outline-primary w-sm ${tabActive === 2 ? 'active':''}`}>
                  Imagens do post
               </button>
            </div>
         </div>}
         <ModalBody>
            {tabActive === 0 && <FormGeneralInfo isEdit={isEdit} topicItem={topicItem} toggle={toggle} />}            
            {tabActive === 1 && <FormCategorias isEdit={isEdit} topicItem={topicItem} toggle={toggle} />}
            {tabActive === 2 && <FormMedia isEdit={isEdit} topicItem={topicItem} toggle={toggle} />}
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   topicItem: PropTypes.object,
}


export default ModalAdd;

