import React, { useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
} from "reactstrap";
import Select from 'react-select';
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { validateEmail } from "utils";
import makeAnimated from 'react-select/animated';
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form";

import {
   getNoSubscribers as onGetNoSubscribers,
   getNoSubscribersSearch as onGetCustomersSearch
} from "store/nosubscribers/actions";

import {
   getNoSubscribersByFilter
} from "api/noSubscribers"

function ModalFiltro({modal, toggle, isEdit}) {
   const dispatch = useDispatch();
   const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
   const [statusSelectedValue, setSelectedStatusValue] = useState(null);
   const [platformSelectedValue, setSelectedPlataformValue] = useState(null);
   const [platformSelectedDateFrom, setSelectedDatefrom] = useState(null);
   const [platformSelectedDateTo, setSelectedDateTo] = useState(null);
   // console.log('statusSelected -> ' + statusSelected)

   const [erros, setErros] = useState(null);

   const animatedComponents = makeAnimated();

   const optionsStatus = [
      {
          options: [
             {
               label: "Todos",
               value: null,
             },
             {
                label: "Aprovado",
                value: "approved"
             },
             {
               label: "Capturado",
               value: "captured"
            },

          ]
      }
  ];

  const optionsPlataform = [
   {
       options: [
         {
            label: "Todos",
            value: null,
         },
         {
             label: "Apple Store",
             value: "applestore"
         },
         {
            label: "Play Store",
            value: "playstore"
         },
         {
            label: "Gateway",
            value: "gateway"
         },
       ]
   }
];

   /**
    * Handling submit customer on customer form
    */
    const handleValidCustomerSubmit = async (e, values) => {
      setSelectedDatefrom(values.from)
      setSelectedDateTo(values.to)
        const payload = {}

      if(statusSelectedValue) {
         payload.status = statusSelectedValue[0].value
      } else {
         setErros("Selecione um Status!")
         return;
      }

      if(platformSelectedValue) {
         payload.platform = platformSelectedValue[0].value
      } else {
         setErros("Selecione uma Plataforma!")
         return;
      }

      if(values.from && values.to) {
         payload.datas = {
            from: values.from,
            to: values.to
         }
      } else {
         setErros("Data Inicial e Final devem ser preenchidas!")
         return;
      }

         dispatch(onGetCustomersSearch(payload))
         toggle()
      
      
   }

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            Filtrar Registros
         </ModalHeader>
         <ModalBody>
            <AvForm
               onValidSubmit={handleValidCustomerSubmit}
            >
               <Row form>
                  <Col className="col-12">
                     <label htmlFor="control-label">Selecione um status</label>
                     <div className="mb-3">
                        <Select
                           label="Status"
                           name="status"
                           value={statusSelectedValue}
                           isMulti={false}
                           onChange={(selected) => setSelectedStatusValue([selected])}
                           options={optionsStatus}
                           classNamePrefix="select2-selection"
                           closeMenuOnSelect={true}
                           components={animatedComponents}
                        />
                     </div> 

                     <label htmlFor="control-label">Selecione uma plataforma</label>
                     <div className="mb-3">
                        <Select
                           label="Plataforma"
                           name="pltaform"
                           value={platformSelectedValue}
                           isMulti={false}
                           onChange={(selected) => setSelectedPlataformValue([selected])}
                           options={optionsPlataform}
                           classNamePrefix="select2-selection"
                           closeMenuOnSelect={true}
                           components={animatedComponents}
                        />
                     </div>

                     {/* <div className="mb-3">
                        <AvField
                           name="email"
                           label="por email"
                           type="email"
                           errorMessage="Email inválido"
                           validate={{
                              required: { value: false },
                           }}
                           value={""}
                        />
                     </div> */}
                     
                     <div className="mt-4">
                        <h6>Selecione a data de expiração da assinatura (Obrigatório)</h6>
                        <div className="d-flex justify-content-between mt-3">
                           <div className="mb-3 w-75" style={{marginRight:20}}>
                              <AvField
                                 name="from"
                                 label="data inicial"
                                 type="date"
                                 errorMessage="data inicial inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value= {platformSelectedDateFrom}
                              />
                           </div>

                           <div className="mb-3 w-75">
                              <AvField
                                 name="to"
                                 label="data final"
                                 type="date"
                                 errorMessage="data final inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value= {platformSelectedDateTo}
                              />
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     {erros && <div className="alert alert-danger">{erros}</div>}
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Procurar
                        </button>
                     </div>
                  </Col>
               </Row>
            </AvForm>
         </ModalBody>
      </Modal>
   );
}

ModalFiltro.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
}


export default ModalFiltro;