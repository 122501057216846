import { 
    GET_POSTS,
    GET_POSTS_SUCCESS,
    GET_POSTS_FAIL,
    ADD_POST,
    ADD_POST_SUCCESS,
    ADD_POST_FAIL,
    UPDATE_POST,
    UPDATE_POST_SUCCESS,
    UPDATE_POST_FAIL,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    DELETE_POST_FAIL
} from "./actionTypes";

export const getPosts = () => ({
    type: GET_POSTS,
});

export const getPostsSuccess = posts => ({
    type: GET_POSTS_SUCCESS,
    payload: posts
});

export const getPostsFail = error => ({
    type: GET_POSTS_FAIL,
    payload: error
});

export const addPost = post => ({
    type: ADD_POST,
    payload: post
});

export const addPostSuccess = post => ({
    type: ADD_POST_SUCCESS,
    payload: post
});

export const addPostFail = error => ({
    type: ADD_POST_FAIL,
    payload: error
});

export const updatePost = post => ({
    type: UPDATE_POST,
    payload: post
});

export const updatePostSuccess = post => ({
    type: UPDATE_POST_SUCCESS,
    payload: post
});

export const updatePostFail = error => ({
    type: UPDATE_POST_FAIL,
    payload: error
})

export const deletePost = post => ({
    type: DELETE_POST,
    payload: post
});

export const deletePostSuccess = post => ({
    type: DELETE_POST_SUCCESS,
    payload: post
});

export const deletePostFail = error => ({
    type: DELETE_POST_FAIL,
    payload: error
})