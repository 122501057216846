/* TOPICS */
export const GET_TOPICS = "GET_TOPICS"
export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS"
export const GET_TOPICS_FAIL = "GET_TOPICS_FAIL"
export const GET_TOPICS_SEARCH = "GET_TOPICS_SEARCH"
export const GET_TOPICS_SEARCH_SUCCESS = "GET_TOPICS_SEARCH_SUCCESS"

/* TOTAL TOPICS */
export const GET_TOTAL_TOPICS = "GET_TOTAL_TOPICS"
export const GET_TOTAL_TOPICS_SUCCESS = "GET_TOTAL_TOPICS_SUCCESS"
export const GET_TOTAL_TOPICS_FAIL = "GET_TOTAL_TOPICS_FAIL"

/* TOPIC SINGLE */
export const GET_TOPIC_SINGLE = "GET_TOPIC_SINGLE"
export const GET_TOPIC_SINGLE_SUCCESS = "GET_TOPIC_SINGLE_SUCCESS"
export const GET_TOPIC_SINGLE_FAIL = "GET_TOPIC_SINGLE_FAIL"

/* TOPIC COMMENTS */
export const GET_TOPIC_COMMENTS = "GET_TOPIC_COMMENTS"
export const GET_TOPIC_COMMENTS_SUCCESS = "GET_TOPIC_COMMENTS_SUCCESS"
export const GET_TOPIC_COMMENTS_FAIL = "GET_TOPIC_COMMENTS_FAIL"
export const GET_TOTAL_COMMENTS = "GET_TOTAL_COMMENTS"
export const GET_PAGE_COMMENTS = "GET_PAGE_COMMENTS"

/* DENUNCIATE */
export const GET_DENUNCIATE = "GET_DENUNCIATE"
export const GET_DENUNCIATE_SEARCH = "GET_DENUNCIATE_SEARCH"
export const GET_DENUNCIATE_SEARCH_SUCCESS = "GET_DENUNCIATE_SEARCH_SUCCESS"
export const GET_DENUNCIATE_SUCCESS = "GET_DENUNCIATE_SUCCESS"

/* LOADINGS */
export const GET_LOADING_COMMENTS = "GET_LOADING_COMMENTS"
export const GET_LOADING_DENUNCIATE = "GET_LOADING_DENUNCIATE"