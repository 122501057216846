import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Users from "../pages/Users"
import EditUser from "pages/Users/EditUser"
import Trainers from "pages/Trainers"
import CategoriasWorkouts from "pages/CategoriasWorkouts"
import Modalities from "pages/Modalities"
import Trainings from "pages/Trainings"
import Recipes from "pages/Recipes"
import Workouts from "pages/Workouts"
import WorkoutsConfig from "pages/WorkoutsConfig"
import WorkoutsDays from "pages/Workouts/WorkoutsDays"
import Equipments from "pages/Equipments"
import VerTreino from "pages/Workouts/VerTreino"
import Categories from "pages/Categories"
import Emphasis from "../pages/Emphasis";
import Subscribers from "pages/reports/Subscribers"
import NoSubscribers from "pages/reports/NoSubscribers"
import Forum from "pages/Community/Forum"
import EditForum from "pages/Community/Forum/EditForum"
import UserDenunciate from "pages/Community/UserDenunciate"
import BlogPosts from "../pages/BlogPosts"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  
  // //profile
  { path: "/profile", component: UserProfile },
  
  // users
  { path: "/clientes", component: Users },
  { path: "/cliente/:id", component: EditUser },

  // trainers
  { path: "/trainers", component: Trainers },

  // destaque
  { path: "/destaques", component: Emphasis },

  // workouts
  { path: "/treinos", component: Workouts },

  // categories
  { path: "/categorias", component: Categories },

  // trainers
  { path: "/treinos/locais", component: CategoriasWorkouts },

  // trainers
  { path: "/treinos/modalidaes", component: Modalities },

  // trainers
  { path: "/treinos/equipamentos", component: Equipments },

  // exercícios
  { path: "/treinos/exercicios", component: Trainings },

  // exercícios
  { path: "/workouts/:id/config", component: WorkoutsConfig },

  // exercícios dia
  { path: "/workouts/:id/dia", component: WorkoutsDays },

   // recipes
   { path: "/receitas", component: Recipes },  
  
   // comunidade
   { path: "/comunidade/forum", component: Forum },
   { path: "/comunidade/topic/edit/:id", component: EditForum },
   { path: "/comunidade/denuncias", component: UserDenunciate },

   // ver treino
   { path: "/workouts/:id/ver/treino", component: VerTreino },  

     // subscribers
  { path: "/reports/subscribers", component: Subscribers },
  
  // no subscribers
  { path: "/reports/nosubscribers", component: NoSubscribers },

  // blog posts
  { path: "/blog/posts", component: BlogPosts },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
