/* EMPHASIS */
export const GET_EMPHASIS = "GET_EMPHASIS"
export const GET_EMPHASIS_SUCCESS = "GET_EMPHASIS_SUCCESS"
export const GET_EMPHASIS_FAIL = "GET_EMPHASIS_FAIL"

/**
 * add EMPHASIS
 */
export const ADD_NEW_EMPHASIS = "ADD_NEW_EMPHASIS"
export const ADD_EMPHASIS_SUCCESS = "ADD_EMPHASIS_SUCCESS"
export const ADD_EMPHASIS_FAIL = "ADD_EMPHASIS_FAIL"

/**
 * Edit EMPHASIS
 */
export const UPDATE_EMPHASIS = "UPDATE_EMPHASIS"
export const UPDATE_EMPHASIS_SUCCESS = "UPDATE_EMPHASIS_SUCCESS"
export const UPDATE_EMPHASIS_FAIL = "UPDATE_EMPHASIS_FAIL"

/**
 * Delete EMPHASIS
 */
export const DELETE_EMPHASIS = "DELETE_EMPHASIS"
export const DELETE_EMPHASIS_SUCCESS = "DELETE_EMPHASIS_SUCCESS"
export const DELETE_EMPHASIS_FAIL = "DELETE_EMPHASIS_FAIL"
