import {
   GET_EMPHASIS,
   GET_EMPHASIS_FAIL,
   GET_EMPHASIS_SUCCESS,
   ADD_EMPHASIS_SUCCESS,
   ADD_EMPHASIS_FAIL,
   UPDATE_EMPHASIS_SUCCESS,
   UPDATE_EMPHASIS_FAIL,
   DELETE_EMPHASIS_SUCCESS,
   DELETE_EMPHASIS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
   emphasis: [],
}

const Emphasis = (state = INIT_STATE, action) => {
   switch (action.type) {

      case GET_EMPHASIS:
         return {
            ...state,
            emphasis: action.payload,
         }

      case GET_EMPHASIS_SUCCESS:
         return {
            ...state,
            emphasis: action.payload,
         }

      case GET_EMPHASIS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case ADD_EMPHASIS_SUCCESS:
         return {
            ...state,
            emphasis: [...state.emphasis, action.payload],
         }

      case ADD_EMPHASIS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case UPDATE_EMPHASIS_SUCCESS:
         return {
            ...state,
            emphasis: state.emphasis.map(emphasis =>
              emphasis.id.toString() === action.payload.id.toString()
                  ? { emphasis, ...action.payload }
                  : emphasis
            ),
         }

      case UPDATE_EMPHASIS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case DELETE_EMPHASIS_SUCCESS:
         return {
            ...state,
            emphasis: state.emphasis.filter(
              emphasis => emphasis.id.toString() !== action.payload.id.toString()
            ),
         }

      case DELETE_EMPHASIS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      default:
         return state
   }
}

export default Emphasis
