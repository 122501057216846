import React from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,   
} from "reactstrap";
import PropTypes from "prop-types";

function ModalImg({ modalFoto, toggleFoto, fotoSelected }) {
   // console.log('fotoSelected', fotoSelected);
   return (
      <Modal isOpen={modalFoto} toggle={toggleFoto}>
         <ModalHeader toggle={toggleFoto} tag="h4">          
         </ModalHeader>
         <ModalBody>
            <form               
            >
             <Row form>
                  <Col className="col-12">
                  <div className="mt-3">
                        <div className="form-group">
                           {fotoSelected && <img src={fotoSelected.url}style={{width: '470px', height: 'auto'}} />}
                        </div>
                     </div>
                  </Col>
               </Row>              
            </form>
         </ModalBody>
      </Modal>
   );
}

ModalImg.propTypes = {
   modalFoto: PropTypes.bool,  
   toggleFoto: PropTypes.func,
   userItem: PropTypes.object,
   fotoSelected: PropTypes.object,
}


export default ModalImg;