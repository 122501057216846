import React, { useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody
} from "reactstrap";
import Select from 'react-select';
import { AvForm, AvField } from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import { validateEmail } from "utils";
import makeAnimated from 'react-select/animated';
import { useDispatch } from "react-redux"
import { useForm } from "react-hook-form";
import Switch from "react-switch";

import {
   getSubscribers as onGetSubscribers,
   getSubscribersSearch as onGetCustomersSearch
} from "store/subscribers/actions";

import {
   findSubscribersByFilter
} from "api/subscribers"

function ModalFiltro({ modal, toggle, isEdit, handleFilterUsed }) {
   const dispatch = useDispatch();
   const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
   const [platformSelectedValue, setSelectedPlataformValue] = useState(null);
   const [platformSelectedDateFrom, setSelectedDatefrom] = useState(null);
   const [platformSelectedDateTo, setSelectedDateTo] = useState(null);
   const [checked, setChecked] = useState(false)

   const [erros, setErros] = useState(null);

   const animatedComponents = makeAnimated();

   const optionsPlataform = [
      {
         options: [
            {
               label: "Todos",
               value: null,
            },
            {
               label: "Apple Store",
               value: "applestore"
            },
            {
               label: "Play Store",
               value: "playstore"
            },
            {
               label: "Gateway",
               value: "gateway"
            },
         ]
      }
   ];

   /**
    * Handling submit customer on customer form
    */
   const handleValidCustomerSubmit = async (e, values) => {
      setSelectedDatefrom(values.from)
      setSelectedDateTo(values.to)
      const payload = {
         noTrial: checked
      }
      let filters = 0;

      if (checked) {
         filters++;
      }

      if (platformSelectedValue) {
         payload.platform = platformSelectedValue[0].value
         filters++;
      }

      if (values.from && values.to) {
         payload.datas = {
            from: values.from,
            to: values.to
         }
         filters++;
      }

      if (filters === 0) {
         setErros("Algum dos filtro deve ser preenchido!")
         return;
      }
      handleFilterUsed(true)
      dispatch(onGetCustomersSearch(payload))
      toggle()
   }

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            Filtrar Registros
         </ModalHeader>
         <ModalBody>
            <AvForm
               onValidSubmit={handleValidCustomerSubmit}
            >
               <Row form>
                  <Col className="col-12">
                     <label htmlFor="control-label">Selecione uma plataforma</label>
                     <div className="mb-3">
                        <Select
                           label="Plataforma"
                           name="pltaform"
                           value={platformSelectedValue}
                           isMulti={false}
                           onChange={(selected) => setSelectedPlataformValue([selected])}
                           options={optionsPlataform}
                           classNamePrefix="select2-selection"
                           closeMenuOnSelect={true}
                           components={animatedComponents}
                        />
                     </div>
                     <div className="mb-3 d-flex align-center">
                        <div style={{marginRight:'10px'}}>Remover trial?</div>
                        <Switch
                           checked={checked}
                           onChange={setChecked}
                           height={20}
                           onColor="#556ee6"
                        />
                     </div>
                     <div className="mt-4">
                        <h6>Selecione a data de expiração da assinatura (Obrigatório)</h6>
                        <div className="d-flex justify-content-between mt-3">
                           <div className="mb-3 w-75" style={{ marginRight: 20 }}>
                              <AvField
                                 name="from"
                                 label="digite uma data"
                                 type="date"
                                 errorMessage="data inicial inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value={platformSelectedDateFrom}
                              />
                           </div>

                           <div className="mb-3 w-75">
                              <AvField
                                 name="to"
                                 label="digite uma data"
                                 type="date"
                                 errorMessage="data final inválida"
                                 validate={{
                                    required: { value: false },
                                 }}
                                 value={platformSelectedDateTo}
                              />
                           </div>
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     {erros && <div className="alert alert-danger">{erros}</div>}
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Procurar
                        </button>
                     </div>
                  </Col>
               </Row>
            </AvForm>
         </ModalBody>
      </Modal>
   );
}

ModalFiltro.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   handleFilterUsed: PropTypes.func,
}


export default ModalFiltro;