import React, { useEffect, useState } from "react";
import {
   Col,
   Row,
   Modal,
   ModalHeader,
   ModalBody,
   Input,
   Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";


import { useDispatch } from "react-redux"

import { addCategories, editCategories } from "../../../api/categories";

import {
   getCategories as onGetCategories,
} from "store/categories/actions";
import ReactSelect from "react-select";

function ModalAdd({ modal, toggle, isEdit, categoryItem }) {
   const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
   const dispatch = useDispatch();

   const [isEditLocal, setIsEditLocal] = useState(false);

   useEffect(() => {
      setIsEditLocal(isEdit);
      reset({
         name: isEdit ? categoryItem.name:'',
         type: isEdit ? categoryItem.type:'',
      });
   }, [isEdit, categoryItem]);

   const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
         const fileReader = new FileReader();
         fileReader.readAsDataURL(file);
         fileReader.onload = () => {
            resolve(fileReader.result);
         };
         fileReader.onerror = (error) => {
            reject(error);
         };
      })
   }

   /**
    * Handling submit customer on customer form
    */
   const handleValidCustomerSubmit = async (data) => {
      console.log('data', data);

      if (data.name && data.thumb) {
         const base64 = data.thumb[0] ? await convertBase64(data.thumb[0]):null;
         console.log(base64);
         if(!isEdit)
         {
            await addCategories({
               name: data.name,
               type: data.type,
               image: base64,
            });
         } else {
            await editCategories({
               _id: categoryWorkoutItem._id,
               name: data.name,
               type: data.type,
               image: base64,
            });
         }

         dispatch(onGetCategories());
         toggle();
      }
   }

   return (
      <Modal isOpen={modal} toggle={toggle}>
         <ModalHeader toggle={toggle} tag="h4">
            {isEdit ? 'Editar' : 'Adicionar'} Categoria
         </ModalHeader>
         <ModalBody>
            <form
               onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
               <Row form>
                  <Col className="col-12">
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="name" className="">Nome</label>
                           <input {...register("name", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.name && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="type" className="">Tipo</label>
                           <select {...register("type", { required: true })} className="form-control item-menu" required>
                              <option value="">Selecione um tipo...</option>
                              <option value="posts">Posts</option>
                              <option value="workouts">Workouts</option>
                              <option value="forum">Forum</option>
                           </select>
                           {errors.type && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     <div className="mt-3">
                        <div className="form-group">
                           <Label htmlFor="thumb" className="form-label">Upload Thumb</Label>
                           <input type="file" {...register("thumb", { required: false })} className="form-control form-control-sm" id="formFileSm" />
                           {errors.thumb && <div className="alert alert-danger">Campo obrigatório</div>}
                           {isEditLocal && <div>
                              <Label htmlFor="thumb" className="form-label mt-2">Preview</Label><br/>
                              <img src={categoryItem?.thumb} style={{width: '100px', height: 'auto'}} />
                           </div>}
                        </div>
                     </div>
                  </Col>
               </Row>
               <Row className="mt-4">
                  <Col>
                     <div className="text-end">
                        <button
                           type="submit"
                           className="btn btn-success save-customer"
                        >
                           Salvar
                        </button>
                     </div>
                  </Col>
               </Row>
            </form>
         </ModalBody>
      </Modal>
   );
}

ModalAdd.propTypes = {
   modal: PropTypes.bool,
   isEdit: PropTypes.bool,
   toggle: PropTypes.func,
   categoryItem: PropTypes.object,
}


export default ModalAdd;