import {
   GET_NOSUBSCRIBERS_FAIL,
   GET_NOSUBSCRIBERS_SUCCESS,
   GET_NOSUBSCRIBERS_SEARCH,
   GET_NOSUBSCRIBERS_SEARCH_CLEAN
} from "./actionTypes"

const INIT_STATE = {
   nosubscribers: [],
   total: 0,
}

const NoSubscribers = (state = INIT_STATE, action) => {
   switch (action.type) {
      case GET_NOSUBSCRIBERS_SUCCESS:
         return {
            ...state,
            nosubscribers: action.payload
         }
      case GET_NOSUBSCRIBERS_FAIL:
         return {
            ...state,
            error: action.payload,
         }
      case GET_NOSUBSCRIBERS_SEARCH_CLEAN:
         return {
            ...state,
            nosubscribeSearch: [],
         }
      case GET_NOSUBSCRIBERS_SEARCH:
         return {
            ...state,
            nosubscribers: action.payload,
         }
      default:
         return state
   }
}
export default NoSubscribers
