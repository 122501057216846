import {
   GET_TOPICS,
   GET_TOPICS_FAIL,
   GET_TOPICS_SUCCESS,
   GET_TOTAL_TOPICS,
   GET_TOTAL_TOPICS_SUCCESS,
   GET_TOTAL_TOPICS_FAIL,
   GET_TOPIC_SINGLE_FAIL,
   GET_TOPIC_SINGLE_SUCCESS,
   GET_TOPIC_COMMENTS_SUCCESS,
   GET_TOTAL_COMMENTS,
   GET_PAGE_COMMENTS,
   GET_LOADING_COMMENTS,
   GET_LOADING_DENUNCIATE,
   GET_DENUNCIATE_SUCCESS,
   GET_DENUNCIATE_SEARCH_SUCCESS,
   GET_TOPICS_SEARCH_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
   isLoading: false,
   isLoadingDenunciate: false,
   topics: [],
   topicDetail: {},
   topicsSearch: [],
   isSearchTopics: false,
   total: 0,
   topicComments: [],
   totalPageComments: 0,
   pageComment: 0,
   denunciate: [],
   denunciateSearch: [],
   isSearch: false
}

const Community = (state = INIT_STATE, action) => {

   switch (action.type) {

      case GET_LOADING_COMMENTS:
         return {
            ...state,
            isLoading: action.payload,
         }

      case GET_LOADING_DENUNCIATE:
         return {
            ...state,
            isLoadingDenunciate: action.payload,
         }

      case GET_TOTAL_TOPICS:
         return {
            ...state,
            total: action.payload,
         }

      case GET_TOTAL_TOPICS_SUCCESS:
         return {
            ...state,
            total: action.payload,
         }

      case GET_TOTAL_TOPICS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case GET_TOPICS_SUCCESS:
         return {
            ...state,
            topics: action.payload,
            isSearchTopics: false
         }

      case GET_TOPICS_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case GET_TOPIC_SINGLE_SUCCESS:
         return {
            ...state,
            topicDetail: action.payload,
         }

      case GET_TOPIC_SINGLE_FAIL:
         return {
            ...state,
            error: action.payload,
         }

      case GET_TOPIC_COMMENTS_SUCCESS:
         return {
            ...state,
            topicComments: action.payload,
         }

      case GET_TOTAL_COMMENTS:
         return {
            ...state,
            totalPageComments: action.payload,
         }

      case GET_PAGE_COMMENTS:
         return {
            ...state,
            pageComment: action.payload,
         }

      case GET_DENUNCIATE_SUCCESS:
         return {
            ...state,
            denunciate: action.payload,
            isSearch: false
         }

      case GET_DENUNCIATE_SEARCH_SUCCESS:
         return {
            ...state,
            denunciateSearch: action.payload,
            isSearch: true
         }
      case GET_TOPICS_SEARCH_SUCCESS:
         return {
            ...state,
            topicsSearch: action.payload,
            isSearchTopics: true
         }

      default:
         return state
   }
}

export default Community
