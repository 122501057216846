import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Button,
} from "reactstrap"

//Import Breadcrumb
import {
    getTopics as onGetTopics,
    getTotalTopics as onGetTotalTopics,
} from "store/community/actions"

import {
    getCategories as onGetCategories,
} from "store/categories/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import ModalFiltro from "../_components/ModalFiltro"
import TableComponent from "../_components/TableComponent"
import ModalAdd from "./ModalAdd"
import { deleteTopic } from "api/community"
// import { deleteUserApi } from "../../api/users"

const Forum = props => {
    const dispatch = useDispatch()

    const { topics } = useSelector(state => ({
        topics: state.Community.topics,
    }))

    const { topicsSearch } = useSelector(state => ({
        topicsSearch: state.Community.topicsSearch,
    }))
    
    const { isSearchTopics } = useSelector(state => ({
        isSearchTopics: state.Community.isSearchTopics,
    }))

    const { total } = useSelector(state => ({
        total: state.Community.total,
    }))

    const [customerList, setCustomerList] = useState([]);
    const [totalCustomer, setTotalCustomer] = useState(0);
    const [modalFilter, setModalFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [topicItem, setTopicItem] = useState();

    //pagination customization
    const pageOptions = {
        sizePerPage: 10,
        totalSize: topics && topics.length, // replace later with size(orders),
        custom: true,
    }

    const EcommerceCustomerColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
            text: "Título",
            dataField: "title",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a style={{ color: 'inherit' }} href={`/comunidade/topic/edit/${row._id}`}>
                    <p className="mb-0">{row.title}</p>
                </a>
            ),
        },
        {
            text: "Categorias",
            dataField: "categories",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                const arrCategoriesName = row.categories.map((item) => item.name);
                return (
                    <a style={{ color: 'inherit' }} href={`/comunidade/topic/edit/${row._id}`}>
                        <p className="mb-0">{arrCategoriesName.join(' | ')}</p>
                    </a>
                )
            },
        },
        {
            text: "Autor",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return (
                    <a style={{ color: 'inherit' }} href={`/comunidade/topic/edit/${row._id}`}>
                        <p className="mb-0">{row.user[0].email}</p>
                    </a>
                )
            },
        },
        {
            dataField: "created_at",
            text: "Cadastro",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <a style={{ color: 'inherit' }} href={`/comunidade/topic/edit/${row._id}`}>{handleValidDate(row.created_at)}</a>,
        },
        {
            text: "created_at_dt",
            dataField: "created_at",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <>{row.created_at}</>,
        },
        {
            dataField: "menu",
            isDummyField: true,
            text: "Ações",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, topic) => (
                <UncontrolledDropdown direction="left">
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                            onClick={() => goModalAdd(true, topic)}
                        >
                            <i className="fas fa-pencil-alt text-success me-1" />
                            Editar
                        </DropdownItem>
                        <DropdownItem
                            onClick={() => handleDeleteTopic(topic)}
                        >
                            <i className="fas fa-trash-alt text-danger me-1" />
                            Excluir
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            ),
        },
    ]

    const toggle = () => {
        setModalFilter(!modalFilter)
    }

    const handleDeleteTopic = async (topic) => {
        if (confirm("Tem certeza que deseja excluir?") == true) {
            await deleteTopic({ _idTopic: topic._id });
            refreshTopicsData()
        }
    }

    function refreshTopicsData() {
        dispatch(onGetTopics())
        dispatch(onGetTotalTopics())
        dispatch(onGetCategories())
    }

    useEffect(() => {
        if (topics && !topics.length) {
            refreshTopicsData()
        }
    }, [topics])

    useEffect(() => {
        console.log('topics', topics)
        setCustomerList(topics)
    }, [topics])

    useEffect(() => {
        // console.log('topicsSearch', topicsSearch);
    }, [topicsSearch])

    useEffect(() => {
        setTotalCustomer(total)
    }, [total])

    useEffect(() => {
        if (!isEmpty(topics)) {
            setCustomerList(topics)
        }
    }, [topics])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        setCustomerList(
            topics.filter(customer =>
                Object.keys(customer).some(key =>
                    customer[key].toLowerCase().includes(searchText.toLowerCase())
                )
            )
        )
    }

    const defaultSorted = [
        {
            dataField: "created_at_dt",
            order: "desc",
        },
    ]

    /** set Date formate */
    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD/MM/YYYY")
        return date1
    }

    function goModalAdd(isEdit, item) {
        if (isEdit) {
            setTopicItem(item);
        }
        setIsEdit(isEdit);
        toggleAdd();
    }

    const toggleAdd = () => {
        setModalAdd(!modalAdd)
    }

    const handleCleanFilter = () => {
        refreshTopicsData();
    }

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Fórum | Suore</title>
                    </MetaTags>
                    <Container fluid>
                        {/* <Breadcrumbs title="Suore" breadcrumbItem="Clientes" /> */}
                        <h3>Fórum</h3>
                        <Row className="mb-2">
                            <Col sm="4"></Col>
                            <Col sm="8">
                                <div className="text-sm-end">
                                    <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={() => goModalAdd(false, null)}
                                    >
                                        <i className="mdi mdi-plus me-1"></i>
                                        Adicionar Tópico
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {customerList && customerList.length > 0 && !isSearchTopics &&
                                            <TableComponent
                                                EcommerceCustomerColumns={EcommerceCustomerColumns}
                                                pageOptions={pageOptions}
                                                data={customerList}
                                                totalCustomer={totalCustomer}
                                                toggle={toggle}
                                                defaultSorted={defaultSorted}
                                                handleTableChange={handleTableChange}
                                                showTotal={1}
                                                showFilter={1}
                                            />}
                                        {topicsSearch && isSearchTopics &&
                                            <TableComponent
                                                EcommerceCustomerColumns={EcommerceCustomerColumns}
                                                pageOptions={pageOptions}
                                                data={topicsSearch}
                                                totalCustomer={topicsSearch.length}
                                                toggle={toggle}
                                                defaultSorted={defaultSorted}
                                                handleTableChange={handleTableChange}
                                                filtered={1}
                                                showTotal={1}
                                                showFilter={1}
                                                cleanFilter={handleCleanFilter}
                                            />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
            <ModalFiltro modal={modalFilter} toggle={toggle} />
            <ModalAdd modal={modalAdd} toggle={toggleAdd} isEdit={isEdit} topicItem={topicItem} />
        </>
    )
}

Forum.propTypes = {
    customers: PropTypes.array,
    total: PropTypes.number,
    onGetTopics: PropTypes.func,
    onAddNewCustomer: PropTypes.func,
    onDeleteCustomer: PropTypes.func,
    onUpdateCustomer: PropTypes.func,
}

export default Forum
