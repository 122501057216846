import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label,
} from "reactstrap";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Loading from "components/Loading";
import SuccessMessage from "components/SuccessMessage";

import { useDispatch } from "react-redux";
import { addMediasTopic, deleteMediasTopic } from "api/community";

import {
    getTopics as onGetTopics,
} from "store/community/actions"

const MAX_LENGTH = 3;

const FormMedia = ({ isEdit, topicItem, toggle }) => {
    const dispatch = useDispatch();

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [imagesUploaded, setImagesUploaded] = useState(null);
    const [isEditLocal, setIsEditLocal] = useState(false);

    useEffect(() => {
        setIsEditLocal(isEdit);
        console.log('topicItem', topicItem);
        setImagesUploaded(topicItem.media)
    }, [isEdit, topicItem]);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        })
    }


    const handleValidCustomerSubmit = async (data) => {
        setSuccessMessage(false);
        setIsLoading(true);

        console.log(data?.medias);
        const arrMedias = []
        for (const [key, value] of Object.entries(data?.medias)) {
            console.log(`${key}: ${value}`);
            const arrMediasBase64 = await convertBase64(value)
            arrMedias.push(arrMediasBase64)
        }
        console.log(arrMedias);

        const objToSend = {
            _idTopic: topicItem._id,
            idUser: topicItem.user[0]._id,
            arrImagesData: arrMedias,
        }

        await addMediasTopic(objToSend);

        setIsLoading(false);
        setSuccessMessage(true);
        onGetTopics();
    }

    const uploadMultipleFiles = (e) => {
        if (Array.from(e.target.files).length > MAX_LENGTH) {
            e.preventDefault();
            alert(`Número máximo de upload por vez é de ${MAX_LENGTH}`);
            return;
        }
    }

    const deleteImage = async (_idMedia, index) => {
        await deleteMediasTopic({
            _idTopic: topicItem._id, 
            _idMedia
        });
        const newArrayImagesUpload = [...imagesUploaded]
        newArrayImagesUpload.splice(index, 1);
        setImagesUploaded(newArrayImagesUpload);
        onGetTopics();
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                    <Col className="col-12">
                        <div className="mt-3">
                            <div className="form-group">
                                <Label htmlFor="medias" className="form-label">Upload Imagens</Label><br />
                                <small>(Não tem limites de imagem mas precisa subir no máximo 3 por vez)</small>
                                <input
                                    type="file"
                                    {...register("medias", { required: !isEdit ? true : false })}
                                    onChange={uploadMultipleFiles}
                                    className="form-control form-control-sm"
                                    id="formFileSm"
                                    multiple
                                />
                                {errors.medias && <div className="alert alert-danger">Campo obrigatório</div>}
                                {imagesUploaded && <><Label htmlFor="medias" className="form-label mt-2">Preview</Label><br /></>}
                                {imagesUploaded && imagesUploaded.map((image, index) => (
                                    <div 
                                        key={`image-${image._id}`}
                                        onClick={()=>deleteImage(image._id, index)}
                                        style={{
                                            display:'inline-block', 
                                            marginRight:'10px', 
                                            position:'relative'}}>
                                        <div style={{
                                            position: 'absolute',
                                            top: '5px',
                                            right: '5px',
                                            cursor: 'pointer'
                                        }}>
                                            <i className="fas fa-trash-alt text-danger" />
                                        </div>
                                        <img src={image.url} style={{ width: '100px', height: 'auto' }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>
            </form>
        </>
    );
}

FormMedia.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    topicItem: PropTypes.object,
}

export default FormMedia;