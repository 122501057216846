import {
   GET_EMPHASIS,
   GET_EMPHASIS_FAIL,
   GET_EMPHASIS_SUCCESS,
   ADD_NEW_EMPHASIS,
   ADD_EMPHASIS_SUCCESS,
   ADD_EMPHASIS_FAIL,
   UPDATE_EMPHASIS,
   UPDATE_EMPHASIS_SUCCESS,
   UPDATE_EMPHASIS_FAIL,
   DELETE_EMPHASIS,
   DELETE_EMPHASIS_SUCCESS,
   DELETE_EMPHASIS_FAIL,
} from "./actionTypes"

export const getEmphasis = () => ({
   type: GET_EMPHASIS,
})

export const getEmphasisSuccess = emphasis => ({
   type: GET_EMPHASIS_SUCCESS,
   payload: emphasis,
})

export const getEmphasisFail = error => ({
   type: GET_EMPHASIS_FAIL,
   payload: error,
})

export const addNewEmphasis = emphasis => ({
   type: ADD_NEW_EMPHASIS,
   payload: emphasis,
})

export const addEmphasisSuccess = emphasis => ({
   type: ADD_EMPHASIS_SUCCESS,
   payload: emphasis,
})

export const addEmphasisFail = error => ({
   type: ADD_EMPHASIS_FAIL,
   payload: error,
})

export const updateEmphasis = emphasis => ({
   type: UPDATE_EMPHASIS,
   payload: emphasis,
})

export const updateEmphasisSuccess = emphasis => ({
   type: UPDATE_EMPHASIS_SUCCESS,
   payload: emphasis,
})

export const updateEmphasisFail = error => ({
   type: UPDATE_EMPHASIS_FAIL,
   payload: error,
})

export const deleteEmphasis = emphasis => ({
   type: DELETE_EMPHASIS,
   payload: emphasis,
})

export const deleteEmphasisSuccess = emphasis => ({
   type: DELETE_EMPHASIS_SUCCESS,
   payload: emphasis,
})

export const deleteEmphasisFail = error => ({
   type: DELETE_EMPHASIS_FAIL,
   payload: error,
})
