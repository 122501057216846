/* SUBSCRIBERS */
export const GET_NOSUBSCRIBERS = "GET_NOSUBSCRIBERS"
export const GET_NOSUBSCRIBERS_SUCCESS = "GET_NOSUBSCRIBERS_SUCCESS"
export const GET_NOSUBSCRIBERS_FAIL = "GET_NOSUBSCRIBERS_FAIL"

/* SUBSCRIBERS SEARCH*/
export const GET_NOSUBSCRIBERS_SEARCH = "GET_NOSUBSCRIBERS_SEARCH"
export const GET_NOSUBSCRIBERS_SEARCH_SUCCESS = "GET_NOSUBSCRIBERS_SEARCH_SUCCESS"
export const GET_NOSUBSCRIBERS_SEARCH_FAIL = "GET_NOSUBSCRIBERS_SEARCH_FAIL"
export const GET_NOSUBSCRIBERS_SEARCH_CLEAN = "GET_NOSUBSCRIBERS_SEARCH_CLEAN"
