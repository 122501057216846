import {post, put, get, del} from "./index";

const addBlogPosts = async (obj) => {
    return await post("/gerenciador/blogposts/add", obj);
}

const getBlogPosts = async () => {
    return await get("/gerenciador/blogposts");
}

const editBlogPosts = async (obj) => {
    return await put("/gerenciador/blogposts/edit", obj);
}

const deleteBlogPosts = async (obj) => {
    return await del("/gerenciador/blogposts/delete", obj);
}

export {
    addBlogPosts,
    getBlogPosts,
    editBlogPosts,
    deleteBlogPosts
};