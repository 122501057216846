import {post, get} from './index';

async function getEmphasis() {
  return await get('/gerenciador/emphasis');
}

async function addEmphasis(obj) {
  return await post(`/gerenciador/emphasis/add`, obj);
}

async function editEmphasis(obj) {
  return await post(`/gerenciador/emphasis/edit`, obj);
}

async function deleteEmphasisApi(obj) {
  return await post(`/gerenciador/emphasis/delete`, obj);
}

export {
  getEmphasis,
  addEmphasis,
  editEmphasis,
  deleteEmphasisApi
}
