import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
   GET_TRAINERS,
   GET_TOTAL_TRAINERS,
   GET_CUSTOMER_DETAIL,
   GET_TRAINERS_SEARCH, GET_EMPHASIS
} from "./actionTypes";
import {
   GET_TRAINERS_FAIL,
   GET_TRAINERS_SUCCESS,
   ADD_CUSTOMER_SUCCESS,
   ADD_CUSTOMER_FAIL,
   UPDATE_CUSTOMER_SUCCESS,
   UPDATE_CUSTOMER_FAIL,
   DELETE_CUSTOMER_SUCCESS,
   DELETE_CUSTOMER_FAIL, getEmphasisFail, getEmphasisSuccess
} from "./actions";

import {
   getTrainers,
} from '../../api/trainers';
import { getTrainersFail, getTrainersSuccess } from "store/actions";
import { getEmphasis } from "../../api/emphasis";

function* fetchEmphasis() {
   try {
      const response = yield call(getEmphasis);
      yield put(getEmphasisSuccess(response.data.emphasis));
   } catch (error) {
      yield put(getEmphasisFail(error));
   }
}

// function* fetchCustomerDetail({ idCliente }) {
//    console.log('idCliente', idCliente);
//    try {
//       const response = yield call(getUsersDetail, idCliente);
//       yield put(geCustomerDetailSuccess(response.data.user));
//       yield put(geCustomerSubscribeSuccess(response.data.orders));
//    } catch (error) {
//       yield put(geCustomerDetailFail(error));
//    }
// }

// function* addCustomerSubscribe({ objToSave }) {
//    try {
//       const response = yield call(addUserSubscribe, objToSave);
//       yield put(addCustomersSubscribeSuccess(response.data.orderSaved));
//    } catch (error) {
//       yield put(addCustomersSubscribeFail(error));
//    }
// }

// function* editCustomerSubscribe({ objToSave }) {
//    try {
//       const response = yield call(editUserSubscribe, objToSave);
//       yield put(editCustomersSubscribeSuccess(response.data.orderUpdated));
//    } catch (error) {
//       yield put(editCustomersSubscribeFail(error));
//    }
// }

// function* deleteCustomer(customer) {
//    try {
//       const response = yield call(deleteUserApi, {_id: customer.payload._id});
//       yield put(deleteCustomerSuccess(response.data));
//    } catch (error) {
//       yield put(deleteCustomerFail(error));
//    }
// }

function* emphasisSaga() {
   
   yield takeEvery(GET_EMPHASIS, fetchEmphasis);
}

export default emphasisSaga
