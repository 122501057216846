import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Label
} from "reactstrap";

import {
    getWorkouts as onGetWorkouts,
 } from "store/workouts/actions";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { addWorkouts, editWorkouts } from "api/workouts";
import { useDispatch } from "react-redux";
import { getStringShortcodeLocale } from "utils";
import SuccessMessage from "components/SuccessMessage";
import Loading from "components/Loading";
import Switch from "react-switch";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();


const FormGeneralInfoWorkouts  = ({ isEdit, toggle, workoutsItem, workoutsDuration })=> {
   
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const dispatch = useDispatch();

    const thumbUrl = workoutsItem?.medias.filter(item=>item.type === "thumb");
 
    const [isEditLocal, setIsEditLocal] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [checked, setChecked] = useState(workoutsItem && workoutsItem?.is_published ? workoutsItem?.is_published : false)
    const [checkedFree, setCheckedFree] = useState(workoutsItem && workoutsItem?.isFree ? workoutsItem?.isFree : false)
    const [checkedWarmingup, setCheckedWarmingup] = useState(workoutsItem && workoutsItem?.warmingup ? workoutsItem?.warmingup : false)

    const durationsSelected = workoutsItem && !workoutsItem?.workoutduration_workouts ? null:{
       label: workoutsItem?.workoutduration_workouts?.name,
       value: workoutsItem?.workoutduration_workouts?._id,
    };

    console.log('durationsSelected', durationsSelected);
   const [selectedDurationValue, setSelectedDurationValue] = useState(durationsSelected ? [durationsSelected]:null);

    useEffect(() => {
       setIsEditLocal(isEdit);
       const name = workoutsItem?.name && workoutsItem?.name.indexOf("[pt]") > -1 ? getStringShortcodeLocale(workoutsItem?.name, 'pt'):workoutsItem?.name;
       const nameEn = workoutsItem?.name && workoutsItem?.name.indexOf("[en]") > -1 ? getStringShortcodeLocale(workoutsItem?.name, 'en'):"";
       const nameEs = workoutsItem?.name && workoutsItem?.name.indexOf("[es]") > -1 ? getStringShortcodeLocale(workoutsItem?.name, 'es'):"";

       reset({
         name: isEdit ? name:'',
         nameEn: isEdit ? nameEn:'',
         nameEs: isEdit ? nameEs:'',
         order_int: isEdit ? workoutsItem.order_int:'',                
       });
    }, [isEdit, workoutsItem]);
 
    const convertBase64 = (file) => {
       return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
             resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
             reject(error);
          };
       })
    }
 
    /**
     * Handling submit customer on customer form
     */
    const handleValidCustomerSubmit = async (data) => {
       
       setSuccessMessage(false);
 
       if (data.name) {
         setIsLoading(true);
         const nameFinal = `[pt]${data.name}[:pt][en]${data.nameEn}[:en][es]${data.nameEs}[:es]`;        
         const base64 = data?.medias[0] ? await convertBase64(data?.medias[0]):null;
          console.log('selectedDurationValue', selectedDurationValue);
          const objToSend = {
            name: nameFinal,               
            medias: base64,
            duration: selectedDurationValue[0] ? selectedDurationValue[0]?.value:'',
            is_published: checked,
            is_free: checkedFree,
            warmingup: checkedWarmingup,
            order_int: data?.order_int,
          }
          if(!isEdit)
          {
             await addWorkouts(objToSend);
          } else {
            objToSend._id = workoutsItem._id;
             await editWorkouts(objToSend);
          }
          setIsLoading(false);
          setSuccessMessage(true);
          dispatch(onGetWorkouts());
         //  toggle();
       }
    }

    return (
        <>
            <form
                onSubmit={handleSubmit(handleValidCustomerSubmit)}
            >
                <Row form>
                <Col className="col-12">
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="name" className="">Nome</label>
                           <input {...register("name", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.name && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="nameEn" className="">Nome Ingles</label>
                           <input {...register("nameEn", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.nameEn && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>
                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="nameEs" className="">Nome Espanhol</label>
                           <input {...register("nameEs", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.nameEs && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>

                     {/* <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="duration" className="">Duração do Treino</label>
                           <input type="number"{...register("duration", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.duration && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>  */}

                     <div className="mb-3 templating-select select2-container">
                        <label htmlFor="control-label">Duração do Treino</label>
                        <Select
                           value={selectedDurationValue}
                           isMulti={false}
                           onChange={(selected) => setSelectedDurationValue([selected])}
                           options={workoutsDuration}
                           classNamePrefix="select2-selection"
                           closeMenuOnSelect={true}
                           components={animatedComponents}
                        />
                     </div>

                     <div className="mb-3">
                        <div className="form-group">
                           <label htmlFor="order_int" className="">Ordem</label>
                           <input type="number"{...register("order_int", { required: false })} className="is-touched is-pristine av-valid form-control" required />
                           {errors.order_int && <div className="alert alert-danger">Campo obrigatório</div>}
                        </div>
                     </div>  

                     <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="control-label" style={{marginRight: "20px", marginBottom: "0"}}>Tem Aquecimento?</label>                                    
                        <Switch
                           checked={checkedWarmingup}
                           onChange={setCheckedWarmingup}
                           height={20}
                           onColor="#556ee6"                                                                              
                        />
                     </div>   

                     <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="control-label" style={{marginRight: "20px", marginBottom: "0"}}>Publicar:</label>                                    
                        <Switch
                           checked={checked}
                           onChange={setChecked}
                           height={20}
                           onColor="#556ee6"                                                                              
                        />
                     </div>    

                     <div className="mb-3 d-flex align-items-center">
                        <label htmlFor="control-label" style={{marginRight: "20px", marginBottom: "0"}}>Grátis?</label>                                    
                        <Switch
                           checked={checkedFree}
                           onChange={setCheckedFree}
                           height={20}
                           onColor="#556ee6"                                                                              
                        />
                     </div>          

                     <div className="mt-3">
                        <div className="form-group">
                           <Label htmlFor="medias" className="form-label">Upload Thumb</Label>
                           <input type="file" {...register("medias", { required: !isEdit ? true:false })} className="form-control form-control-sm" id="formFileSm" />
                           {errors.medias && <div className="alert alert-danger">Campo obrigatório</div>}
                           {isEditLocal && <div>
                              <Label htmlFor="medias" className="form-label mt-2">Preview</Label><br/>
                              <img src={thumbUrl[0].url} style={{width: '100px', height: 'auto'}} />
                           </div>}
                        </div>
                     </div>
                  </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {successMessage && <SuccessMessage />}
                        <div className="text-end">
                            {!isLoading && <button
                                type="submit"
                                className="btn btn-success save-customer"
                            >
                                Salvar
                            </button>}
                            {isLoading && <Loading />}
                        </div>
                    </Col>
                </Row>               
            </form>
        </>
    );
}

FormGeneralInfoWorkouts.propTypes = {
    toggle: PropTypes.func,
    isEdit: PropTypes.bool,
    workoutsItem: PropTypes.object,
    workoutsDuration: PropTypes.array,
 }

export default FormGeneralInfoWorkouts;