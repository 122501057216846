import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
   GET_NOSUBSCRIBERS,
   GET_NOSUBSCRIBERS_SEARCH,
} from "./actionTypes"
import {
   getNoSubscribersSuccess,
   getNoSubscribersFail,
} from "./actions"

import {
   findNoSubscribersByFilter
} from '../../api/noSubscribers.js';

function* fetchNoSubscriberSearch({ search }) {
   try {
      const response = yield call(findNoSubscribersByFilter, search);
      
      if(response.status == 204) {
         yield put(getNoSubscribersFail(error));
      } else {
         yield put(getNoSubscribersSuccess(response.data.users));
      }

      yield put(getNoSubscribersSuccess(response.data.users));
   } catch (error) {
      yield put(getNoSubscribersFail(error));
   }
}

function* noSubscribersSaga() {
   yield takeEvery(GET_NOSUBSCRIBERS, fetchNoSubscriberSearch);
   yield takeEvery(GET_NOSUBSCRIBERS_SEARCH, fetchNoSubscriberSearch);
}

export default noSubscribersSaga
