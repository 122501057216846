import React, { useEffect, useState } from "react";
import {
   Col,
   Row,
} from "reactstrap";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Loading from "components/Loading";
import { addTopic, approveAction, disapproveAction, editTopic } from "api/community";

import {
   getDenunciate as onGetDenunciate,
} from "store/community/actions"

const FormResponderDenuncia = ({ isEdit, toggle, denunciateItem, entity }) => {
   const { register, handleSubmit, reset, formState: { errors } } = useForm();
   const dispatch = useDispatch();

   const [isEditLocal, setIsEditLocal] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const [selectedAuthor, setSelectedAuthor] = useState(null);

   useEffect(() => {
      setIsEditLocal(isEdit);
      console.log('denunciateItem', denunciateItem);
   }, [isEdit, denunciateItem]);

   const renderFromDescription = () => {
      console.log('entity', entity);
      if (denunciateItem.from === "forum") {
         return (
            <>
               <h5>Título</h5>
               <p>{entity.title}</p>
               <h5>Texto</h5>
               <p>{entity.text}</p>
               {entity?.media && entity?.media.length > 0 && <h5>Imagens</h5>}
               {entity?.media && entity?.media.length > 0 && entity?.media.map((image, idx) => {
                  return <img key={`image-${idx}`} src={image} />
               })}
            </>
         )
      }
   }

   const handleOnApprove = () => {
      if (confirm(`Tem certeza que deseja aprovar?\nAprovar uma denúncia significa que o post/comentário não está de acordo com nossa política. Portanto o posts será despublicado no momento em que confirmar a aprovação`) == true) {
         approveAction({
            _id: denunciateItem._id,
            _idEntity: denunciateItem.id_entity,
            from: denunciateItem.from,
         })
         dispatch(onGetDenunciate())
         toggle()
      }
   }

   const handleOnDisapprove = () => {
      if (confirm(`Tem certeza que deseja desaprovar?\nDesaprovar uma denúncia significa que o post/comentário está de acordo com nossa política. Portanto o posts será mantido e nada será alterado no momento em que confirmar a desaprovação`) == true) {
         disapproveAction({
            _id: denunciateItem._id,
            _idEntity: denunciateItem.id_entity,
            from: denunciateItem.from,
         })
         dispatch(onGetDenunciate())
         toggle()
      }
   }

   return (
      <>
         {denunciateItem.approved === 1 && <Row form>
            <div className="bg-success flex justify-content-center align-center text-white w-25 rounded-3 h-50">
               <div className="p-3 text-sm-center mb-3 font-size-14">
                  Aprovado
               </div>
            </div>
         </Row>}
         {denunciateItem.approved === 2 && <Row form>
            <div className="bg-danger flex justify-content-center align-center text-white w-25 rounded-3 h-50">
               <div className="p-3 text-sm-center mb-3 font-size-14">
                  Reprovado
               </div>
            </div>
         </Row>}
         <Row form>
            <Col className="col-12">
               <div className="mb-3">
                  <div className="form-group">
                     <h5>Autor da Denúncia</h5>
                     <p>{denunciateItem.user[0].email}</p>
                  </div>
                  <div className="form-group">
                     <h5>Razão</h5>
                     <p>{denunciateItem.reason}</p>
                  </div>
                  <div className="form-group">
                     {entity && renderFromDescription()}
                  </div>
               </div>
            </Col>
         </Row>
         <Row className="mt-4">
            <Col>
               <div className="text-end">
                  {!isLoading && denunciateItem.approved !== 2 &&  <button
                     type="submit"
                     className="btn btn-danger save-customer"
                     style={{ marginRight: '15px' }}
                     onClick={() => handleOnDisapprove()}
                  >
                     Reprovar
                  </button>}
                  {!isLoading && denunciateItem.approved !== 1 && <button
                     type="submit"
                     className="btn btn-success save-customer"
                     onClick={() => handleOnApprove()}
                  >
                     Aprovar
                  </button>}
                  {isLoading && <Loading />}
               </div>
            </Col>
         </Row>
      </>
   );
}

FormResponderDenuncia.propTypes = {
   toggle: PropTypes.func,
   isEdit: PropTypes.bool,
   denunciateItem: PropTypes.object,
   entity: PropTypes.object,
}

export default FormResponderDenuncia;