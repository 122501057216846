import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { getTrainingsApi } from 'api/trainings';
import { getStringShortcodeLocale } from 'utils';
import {
    Container,
    Row,
    Col,
    Alert,
} from "reactstrap";
import Loading from 'components/Loading';


const RenderSettings = ({item}) => {

    const [exercisesData, setExercisesData] = useState(null);
    const [closedContainers, setClosedContainers] = useState([]);

    useEffect(()=>{
        fetchSettings();
    },[]);

    async function fetchSettings() {
        if(item.settings) {
            const settings = JSON.parse(item.settings);
            console.log('settings', settings);
            const objExercises = [];
            for (let index = 0; index < settings.length; index++) {
                const circuit = settings[index];
                // console.log(circuit);
                objExercises[index] = {
                    circuit: circuit.text,
                    settings: []
                };
                if(!circuit.children) {
                    alert(`Não tem exercícios no dia ${item.name} circuito ${circuit.text}`);
                    return;
                }
                for (let u = 0; u < circuit.children.length; u++) {
                    const element = circuit.children[u];
                    if(element.exercicio_id) {
                        const training = await getTrainingsApi({trainingid: element.exercicio_id});
                        try {
                            if(training.data.data[0]) {
                                objExercises[index]['settings'].push({
                                    name: element.text,
                                    categorie: element.categorie,
                                    video: training.data.data[0].videoUrl,
                                    audio: training.data.data[0].audioUrl,
                                    thumbUrl: training.data.data[0].thumbUrl,
                                });
                            }
                        } catch(error) {
                            console.log('training',training, error, element.text, item);
                            alert(`O treino ${element.text} do id ${element.exercicio_id} no circuito ${circuit.text} no dia ${item.name} e semana ${item.weekDay} não foi encontrado na base de dados.`);
                        }
                    } else if(element.categorie === 'descanso') {
                        objExercises[index]['settings'].push({
                            name: element.text,
                            categorie: element.categorie
                        });
                    }
                }
            }

            // console.log('objExercises',objExercises);
            setExercisesData(objExercises);
        }
    }

    function renderLiSettings(settings) {
        const arrReturn = [];
        for (let index = 0; index < settings.length; index++) {
            const element = settings[index];
            console.log('element', element);
            arrReturn.push(
                <Col md={3} className="mb-4 d-flex flex-column justify-content-center align-content-center">
                    <p>{element.name}</p>
                    {element.categorie === 'descanso' && <div className="container-descanso"><i className="far fa-clock" style={{fontSize:'100px'}}></i></div>}
                    {element.categorie !== 'descanso' && <video width="320" height="240" controls>
                        <source src={element.video} type="video/mp4" />
                    </video>}
                    <audio controls className="mt-2">
                        <source src={element.audio} type="audio/mp3" />
                    </audio>
                    {element.thumbUrl && <div><img src={element.thumbUrl} width={80} style={{marginTop:30}} /></div>}
                </Col>
            );
        }

        return arrReturn;
    }

    function handleCollapse(index) {
        const newArrClosed = [...closedContainers];
        if(newArrClosed.includes(index)) {
            newArrClosed.splice(index, 1);
        } else {
            newArrClosed.push(index);
        }
        setClosedContainers(newArrClosed);
    }

    return <>
        <div>
            {!exercisesData && <Loading />}
            {exercisesData && exercisesData.map((circuit, index)=>(
                <div key={`circuit-${index}`} className="box-circuit" style={{marginBottom:'30px', marginTop:'30px'}}>
                    <div className="d-flex justify-content-between align-content-center cursor-pointer" onClick={()=>handleCollapse(index)}>
                        <h5 className="mb-4">{circuit.circuit}</h5>
                        <i className={`fas ${!closedContainers.includes(index) ? "fa-minus":"fa-plus"}`} style={{fontSize:'16px'}}></i>
                    </div>
                    <Container fluid className={closedContainers.includes(index) && "collapse"}>
                        <Row>
                            {renderLiSettings(circuit.settings)}
                        </Row>
                    </Container>
                </div>
            ))}
        </div>
    </>;
}

RenderSettings.propTypes = {
    item: PropTypes.object,
}


export default RenderSettings;