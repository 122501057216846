import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import {
    Button,
    Col,
    Row,
    Label,
    Modal,
    ModalHeader,
    ModalBody
} from "reactstrap";
import Switch from "react-switch";
import { Editor } from "@tinymce/tinymce-react";

import { useDispatch } from "react-redux";

import { getPosts as onGetBlogPosts } from "../../../store/posts/actions";

import {
    addBlogPosts,
    editBlogPosts
} from "../../../api/blogPosts";

import moment from "moment";

function ModalAdd({ modal, isEdit, toggle, postItem }) {

    const dispatch = useDispatch();

    const editorRef = useRef(null);
    const [editorError, setEditorError] = useState(null);

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const [checkedIsPublished, setCheckedIsPublished] = useState(false);
    const [initialTextValue, setInitialTextValue] = useState("");

    useEffect(() => {
        reset({
            title: isEdit ? postItem.title : "",
            publishDate: isEdit ? postItem.publishDate : moment().format("YYYY-MM-DD"),
            isPublished: isEdit ? postItem.isPublished : false
        });
        setInitialTextValue(isEdit ? postItem.content : "");

        if (isEdit) {
            setCheckedIsPublished(postItem.isPublished);
        }
    }, [toggle, postItem, isEdit])

    const handleEditorError = () => {
        if (editorRef.current.getContent() === "") {
            setEditorError({
                message: "",
                ref: editorRef,
                type: "required"
            });
        } else {
            setEditorError(null);
        }
    }

    const handleValidPostSubmit = async (data) => {

        handleEditorError();
        if (data.title && editorRef.current.getContent()) {
            let editorContent = editorRef.current.getContent();

            const objPost = {
                title: data.title.trim(),
                isPublished: checkedIsPublished,
                content: editorContent,
                publishDate: data.publishDate
            }
            if (!isEdit) {
                await addBlogPosts(objPost);
            } else {
                //O ERRO PROVAVELMENTE OCORRE ABAIXO\
                objPost._id = postItem._id
                await editBlogPosts(objPost);
            }
            toggle();
            dispatch(onGetBlogPosts());
        }
    }

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
                {isEdit ? "Editar" : "Adicionar"} Post
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(handleValidPostSubmit, handleEditorError)}>
                    <Row form>
                        <Col className="col-12">
                            <div className="mb-3">
                                <div className="form-group">
                                    <Label htmlFor="title">Título</Label>
                                    <input
                                        {...register("title", { required: true })}
                                        className="is-touched is-pristine av-valid form-control"
                                    />
                                    {errors.title && <div className="alert alert-danger">Campo obrigatório</div>}
                                </div>
                            </div>
                            <div className="form-group">
                                <Label htmlFor="">Publicado?</Label>
                                <Switch
                                    checked={checkedIsPublished}
                                    onChange={setCheckedIsPublished}
                                    height={20}
                                    onColor="#556EE6"
                                />
                            </div>
                            <div className="form-group">
                                <Label htmlFor="publishDate">Data de Publicação</Label>
                                <input
                                    {...register("publishDate")}
                                    className="is-touched is-pristine av-valid form-control"
                                    type="date"
                                />
                            </div>
                            <div className="mb-3">
                                <div className="form-group">
                                    <label htmlFor="content" className="">Conteúdo</label>
                                    <Editor
                                        apiKey="8ioqytzwgdiq5kh99u26h5w3vwc306k713okkf806f7hbhv5"
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        initialValue={initialTextValue}
                                        className="is-touched is-pristine av-valid form-control"
                                        init={{
                                            height: 500,
                                            menubar: false,
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar: 'undo redo | formatselect | ' +
                                                'bold italic backcolor | alignleft aligncenter ' +
                                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                                'removeformat | help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color:#fff }'
                                        }}
                                    />
                                    {editorError && <div className="alert alert-danger">Campo obrigatório</div>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <div className="text-end">
                                <Button
                                    color="success"
                                    className="btn save-customer"
                                    type="submit"
                                >
                                    Salvar
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </ModalBody>
        </Modal>
    );
}

ModalAdd.propTypes = {
    modal: PropTypes.bool,
    isEdit: PropTypes.bool,
    toggle: PropTypes.func,
    postItem: PropTypes.object
}

export default ModalAdd;