import {
   GET_NOSUBSCRIBERS_SEARCH_CLEAN,
   GET_NOSUBSCRIBERS_SEARCH,
   GET_NOSUBSCRIBERS_SUCCESS,
   GET_NOSUBSCRIBERS_FAIL,
   GET_NOSUBSCRIBERS
} from "./actionTypes"

export const getNoSubscribers = payload => ({
   type: GET_NOSUBSCRIBERS,
   payload: payload
})

export const getNoSubscribersSuccess = nosubscribers => ({
   type: GET_NOSUBSCRIBERS_SUCCESS,
   payload: nosubscribers,
})


export const getNoSubscribersFail = error => ({
   type: GET_NOSUBSCRIBERS_FAIL,
   payload: error,
})

export const getNoSubscribersSearch = search => ({
   type: GET_NOSUBSCRIBERS_SEARCH,
   search
})

export const getSubscribeSearchClean = () => ({
   type: GET_NOSUBSCRIBERS_SEARCH_CLEAN
})