const authorsArray = [
    {
        label: 'Fernanda Queiroz',
        value: "612ee7627be6a761277a940a"
    },
    {
        label: 'Ju da Suore',
        value: "6244b8f76447836b233960ef"
    },
    {
        label: 'Juliana de Oliveira',
        value: "6193bf4c9053cb3de1cf98ee"
    },
    {
        label: 'Raissa',
        value: "618ebc3f3331f41e2fe4d66e"
    },
    {
        label: 'Joice Campos',
        value: "612ee7647be6a761277abc98"
    },
    {
        label: 'Karine',
        value: "612ee76f7be6a761277bc680"
    },
    {
        label: 'Lyka Ribeiro',
        value: "612ee76f7be6a761277bc627"
    },
]

export {
    authorsArray,
}