import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { isEmpty } from "lodash"
import * as moment from "moment"
import {
   Card,
   CardBody,
   Col,
   Container,
   Row,
   Button,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   UncontrolledDropdown,
} from "reactstrap"

//Import Breadcrumb
import {
   getEmphasis as onGetEmphasis,
} from "store/emphasis/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import TableComponent from "./_components/TableComponent"
import { deleteUserApi } from "../../api/users"
import ModalAdd from "./_components/ModalAdd"
import { deleteEmphasisApi } from "api/emphasis"

const Emphasis = props => {
   const dispatch = useDispatch()

   const { emphasis } = useSelector(state => ({
      emphasis: state.emphasis.emphasis,
   }))

   const [emphasisList, setEmphasisList] = useState([]);
   const [isEdit, setIsEdit] = useState(false);
   const [emphasisItem, setEmphasisItem] = useState(false);
   const [modalAdd, setModalAdd] = useState(false);

   //pagination customization
   const pageOptions = {
      sizePerPage: 10,
      totalSize: emphasis && emphasis.length, // replace later with size(orders),
      custom: true,
   }

   function goModalAdd(isEdit, item) {
      if(isEdit) {
         setEmphasisItem(item);
      }
      setIsEdit(isEdit);
      toggle();
   }

   const handleDeleteTrainer = async (emphasis) => {
      if (confirm("Tem certeza que deseja excluir?") == true) {
         await deleteEmphasisApi({_id: emphasis._id});
         dispatch(onGetEmphasis());
      }
   }

   const EcommerceCustomerColumns = [
      {
         text: "id",
         dataField: "id",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.id}</>,
      },
      {
         text: "Nome",
         dataField: "name",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => (
            <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>
               <p className="mb-0">{row.name}</p>
            </a>
         ),
      },
      {
         dataField: "created_at",
         text: "Cadastro",
         sort: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <a style={{color:'inherit'}} onClick={()=>goModalAdd(true, row)}>{handleValidDate(row.created_at)}</a>,
      },
      {
         text: "created_at_dt",
         dataField: "created_at",
         sort: true,
         hidden: true,
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, row) => <>{row.created_at}</>,
      },
      {
         dataField: "menu",
         isDummyField: true,
         text: "Ações",
         // eslint-disable-next-line react/display-name
         formatter: (cellContent, item) => (
            <UncontrolledDropdown direction="left">
               <DropdownToggle href="#" className="card-drop" tag="i">
                  <i className="mdi mdi-dots-horizontal font-size-18" />
               </DropdownToggle>
               <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                     onClick={()=>goModalAdd(true, item)}
                  >
                     <i className="fas fa-pencil-alt text-success me-1" />
                     Editar
                  </DropdownItem>
                  <DropdownItem
                     href="#"
                     onClick={() => handleDeleteTrainer(item)}
                  >
                     <i className="fas fa-trash-alt text-danger me-1" />
                     Excluir
                  </DropdownItem>
               </DropdownMenu>
            </UncontrolledDropdown>
         ),
      },
   ]

   const toggle = () => {
      setModalAdd(!modalAdd)
   }

   useEffect(() => {
      if (emphasis && !emphasis.length) {
         dispatch(onGetEmphasis())
      }
   }, [dispatch, emphasis])

   useEffect(() => {
      setEmphasisList(emphasis)
   }, [emphasis])

   useEffect(() => {
      if (!isEmpty(emphasis)) {
         setEmphasisList(emphasis)
      }
   }, [emphasis])

   // eslint-disable-next-line no-unused-vars
   const handleTableChange = (type, { page, searchText }) => {
      setEmphasisList(
        emphasis.filter(emphasis =>
            Object.keys(emphasis).some(key =>
              emphasis[key].toLowerCase().includes(searchText.toLowerCase())
            )
         )
      )
   }

   const defaultSorted = [
      {
         dataField: "created_at_dt",
         order: "desc",
      },
   ]

   /** set Date formate */
   const handleValidDate = date => {
      const date1 = moment(new Date(date)).format("DD/MM/YYYY")
      return date1
   }

   return (
      <>
      <React.Fragment>
         <div className="page-content">
            <MetaTags>
               <title>Destaques | Suore</title>
            </MetaTags>
            <Container fluid>
               {/* <Breadcrumbs title="Suore" breadcrumbItem="Destaques" /> */}
               <h3>Destaques</h3>
               <Row className="mb-2">
                  <Col sm="4"></Col>
                  <Col sm="8">
                     <div className="text-sm-end">
                        <Button
                           type="button"
                           color="success"
                           className="btn-rounded mb-2 me-2"
                           onClick={()=>goModalAdd(false, null)}
                        >
                           <i className="mdi mdi-plus me-1"></i>
                           Adicionar destaque
                        </Button>
                     </div>
                  </Col>
               </Row>
               <Row>
                  <Col xs="12">
                     <Card>
                        <CardBody>
                           {emphasis && emphasis.length > 0 &&
                           <TableComponent 
                           EcommerceCustomerColumns={EcommerceCustomerColumns} 
                           pageOptions={pageOptions} 
                           data={emphasis}
                           toggle={toggle} 
                           defaultSorted={defaultSorted} 
                           handleTableChange={handleTableChange} 
                           />}
                        </CardBody>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </React.Fragment>
      <ModalAdd modal={modalAdd} toggle={toggle} isEdit={isEdit} emphasisItem={emphasisItem} />
      </>
   )
}

Emphasis.propTypes = {
   emphasis: PropTypes.array,
   total: PropTypes.number,
   onGetEmphasis: PropTypes.func,
   onAddNewEmphasis: PropTypes.func,
   onDeleteEmphasis: PropTypes.func,
   onUpdateEmphasis: PropTypes.func,
}

export default Emphasis
