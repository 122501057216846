import {
   GET_TOPICS,
   GET_TOPICS_FAIL,
   GET_TOPICS_SUCCESS,
   GET_TOTAL_TOPICS,
   GET_TOTAL_TOPICS_SUCCESS,
   GET_TOTAL_TOPICS_FAIL,
   GET_TOPIC_SINGLE,
   GET_TOPIC_SINGLE_SUCCESS,
   GET_TOPIC_SINGLE_FAIL,
   GET_TOPIC_COMMENTS,
   GET_TOPIC_COMMENTS_SUCCESS,
   GET_TOPIC_COMMENTS_FAIL,
   GET_TOTAL_COMMENTS,
   GET_PAGE_COMMENTS,
   GET_LOADING_COMMENTS,
   GET_DENUNCIATE,
   GET_DENUNCIATE_SUCCESS,
   GET_LOADING_DENUNCIATE,
   GET_DENUNCIATE_SEARCH,
   GET_DENUNCIATE_SEARCH_SUCCESS,
   GET_TOPICS_SEARCH,
   GET_TOPICS_SEARCH_SUCCESS,
} from "./actionTypes"

export const getTotalTopics = () => ({
   type: GET_TOTAL_TOPICS,
})

export const getTotalTopicsSuccess = topics => ({
   type: GET_TOTAL_TOPICS_SUCCESS,
   payload: topics,
})

export const getTotalTopicsFail = error => ({
   type: GET_TOTAL_TOPICS_FAIL,
   payload: error,
})

export const getTopics = () => {
   return ({
      type: GET_TOPICS,
   })
}

export const getTopicsSuccess = topics => ({
   type: GET_TOPICS_SUCCESS,
   payload: topics,
})

export const getTopicsFail = error => ({
   type: GET_TOPICS_FAIL,
   payload: error,
})

export const getTopicsSearch = (_objFilter) => {
   return ({
      type: GET_TOPICS_SEARCH,
      _objFilter
   })
}

export const getTopicsSearchSuccess = topics => ({
   type: GET_TOPICS_SEARCH_SUCCESS,
   payload: topics,
})

export const getTopicDetail = (_idTopic) => {
   return ({
      type: GET_TOPIC_SINGLE,
      _idTopic,
   })
}

export const getTopicDetailSuccess = topics => ({
   type: GET_TOPIC_SINGLE_SUCCESS,
   payload: topics,
})

export const getTopicDetailFail = error => ({
   type: GET_TOPIC_SINGLE_FAIL,
   payload: error,
})

export const getTopicComments = (_objFilterComments) => {
   return ({
      type: GET_TOPIC_COMMENTS,
      _objFilterComments,
   })
}

export const getTopicCommentsSuccess = comments => ({
   type: GET_TOPIC_COMMENTS_SUCCESS,
   payload: comments,
})

export const getTopicCommentsFail = error => ({
   type: GET_TOPIC_COMMENTS_FAIL,
   payload: error,
})

export const getTopicCommentsTotalPage = totalPage => ({
   type: GET_TOTAL_COMMENTS,
   payload: totalPage,
})

export const getTopicCommentsCurrentPage = page => ({
   type: GET_PAGE_COMMENTS,
   payload: page,
})

export const getIsLoadingComments = isLoading => ({
   type: GET_LOADING_COMMENTS,
   payload: isLoading,
})

export const getDenunciate = (_objFilterDenunciate) => {
   return ({
      type: GET_DENUNCIATE,
      _objFilterDenunciate,
   })
}

export const getDenunciateSearch = (_objFilterDenunciate) => {
   return ({
      type: GET_DENUNCIATE_SEARCH,
      _objFilterDenunciate,
   })
}

export const getDenunciateSearchSuccess = denunciate => {
   return ({
      type: GET_DENUNCIATE_SEARCH_SUCCESS,
      payload: denunciate,
   })
}

export const getDenunciateSuccess = denunciate => ({
   type: GET_DENUNCIATE_SUCCESS,
   payload: denunciate,
})

export const getIsLoadingDenunciate = isLoading => ({
   type: GET_LOADING_DENUNCIATE,
   payload: isLoading,
})
