import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import * as moment from "moment"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
    Button,
} from "reactstrap"

//Import Breadcrumb
import {
    getDenunciate as onGetDenunciate,
} from "store/community/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import ModalFiltro from "./_components/ModalFiltro"
import TableComponent from "../_components/TableComponent"
import ModalAdd from "./ModalAdd"
import Loading from "components/Loading"

const UserDenunciate = props => {
    const dispatch = useDispatch()

    const { denunciate } = useSelector(state => ({
        denunciate: state.Community.denunciate,
    }))
    
    const { denunciateSearch } = useSelector(state => ({
        denunciateSearch: state.Community.denunciateSearch,
    }))

    const { isSearch } = useSelector(state => ({
        isSearch: state.Community.isSearch,
    }))

    const { isLoadingDenunciate } = useSelector(state => ({
        isLoadingDenunciate: state.Community.isLoadingDenunciate,
    }))

    const [denunciateList, setDenunciateList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [alreadyLoaded, setAlreadyLoaded] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [modalAdd, setModalAdd] = useState(false);
    const [denunciateItem, setDenunciateItem] = useState();

    //pagination customization
    const pageOptions = {
        sizePerPage: 10,
        totalSize: denunciate && denunciate.length, // replace later with size(orders),
        custom: true,
    }

    const EcommerceCustomerColumns = [
        {
            text: "id",
            dataField: "id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
            text: "Onde?",
            dataField: "from",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                let from = 'Comentário'
                if(row.from === 'forum') from = "Tópico"
                return (
                    <a style={{ color: 'inherit' }} onClick={() => goModalAdd(true, row)}>
                        <p className="mb-0">{from}</p>
                    </a>
                )
            },
        },
        {
            text: "Razão",
            dataField: "reason",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return (
                    <a style={{ color: 'inherit' }} onClick={() => goModalAdd(true, row)}>
                        <p className="mb-0">{row.reason}</p>
                    </a>
                )
            },
        },
        {
            text: "Autor Denúncia",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                return (
                    <a style={{ color: 'inherit' }} onClick={() => goModalAdd(true, row)}>
                        <p className="mb-0">{row.user[0].email}</p>
                    </a>
                )
            },
        },
        {
            text: "Status",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => {
                const approved = row.approved;
                let status = "Não Respondido";
                if (approved === 1) status = "aprovado"
                if (approved === 2) status = "reprovado"

                return (
                    <a style={{ color: 'inherit' }} onClick={() => goModalAdd(true, row)}>
                        <p className="mb-0">{status}</p>
                    </a>
                )
            },
        },
        {
            dataField: "created_at",
            text: "Cadastro",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <a style={{ color: 'inherit' }} onClick={() => goModalAdd(true, row)}>{handleValidDate(row.created_at)}</a>,
        },
        {
            text: "created_at_dt",
            dataField: "created_at",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <>{row.created_at}</>,
        },
        {
            dataField: "menu",
            isDummyField: true,
            text: "Ações",
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, denunciate) => (
                <UncontrolledDropdown direction="left">
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem
                            onClick={() => goModalAdd(true, denunciate)}
                        >
                            <i className="fas fa-pencil-alt text-success me-1" />
                            Responder
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            ),
        },
    ]

    const toggle = () => {
        setModalFilter(!modalFilter)
    }

    function refreshTopicsData() {
        dispatch(onGetDenunciate())
    }

    useEffect(() => {
        if (denunciate && !denunciate.length && !alreadyLoaded) {
            refreshTopicsData()
            setAlreadyLoaded(true)
        }
    }, [denunciate])

    useEffect(() => {
        console.log('denunciate', denunciate)
        setDenunciateList(denunciate)
    }, [denunciate])

    useEffect(() => {
        console.log('isLoadingDenunciate', isLoadingDenunciate)
        setIsLoading(isLoadingDenunciate)
    }, [isLoadingDenunciate])

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }) => {
        setCustomerList(
            topics.filter(customer =>
                Object.keys(customer).some(key =>
                    customer[key].toLowerCase().includes(searchText.toLowerCase())
                )
            )
        )
    }

    const defaultSorted = [
        {
            dataField: "created_at_dt",
            order: "desc",
        },
    ]

    /** set Date formate */
    const handleValidDate = date => {
        const date1 = moment(new Date(date)).format("DD/MM/YYYY")
        return date1
    }

    function goModalAdd(isEdit, item) {
        if (isEdit) {
            setDenunciateItem(item);
        }
        setIsEdit(isEdit);
        toggleAdd();
    }

    const toggleAdd = () => {
        setModalAdd(!modalAdd)
    }

    const handleCleanFilter = () => {
        refreshTopicsData();
    }

    return (
        <>
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Denúncias | Suore</title>
                    </MetaTags>
                    <Container fluid>
                        {/* <Breadcrumbs title="Suore" breadcrumbItem="Clientes" /> */}
                        <h3>Denúncias</h3>
                        <Row className="mb-2">
                            <Col sm="12"></Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {isLoading && <Loading />}
                                        {!denunciate.length && !isLoading && <div>Nenhuma denúncia até o momento.</div>}
                                        {denunciateList && denunciateList.length > 0 && !isLoading && !isSearch &&
                                            <TableComponent
                                                EcommerceCustomerColumns={EcommerceCustomerColumns}
                                                pageOptions={pageOptions}
                                                data={denunciateList}
                                                totalCustomer={denunciateList.length}
                                                toggle={toggle}
                                                defaultSorted={defaultSorted}
                                                handleTableChange={handleTableChange}
                                                showTotal={1}
                                                showFilter={1}
                                            />}
                                        {!isLoading && isSearch && 
                                            <TableComponent
                                                EcommerceCustomerColumns={EcommerceCustomerColumns}
                                                pageOptions={pageOptions}
                                                data={denunciateSearch}
                                                totalCustomer={denunciateSearch.length}
                                                toggle={toggle}
                                                defaultSorted={defaultSorted}
                                                handleTableChange={handleTableChange}
                                                filtered={1}
                                                showTotal={1}
                                                showFilter={1}
                                                cleanFilter={handleCleanFilter}
                                            />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
            <ModalFiltro modal={modalFilter} toggle={toggle} />
            <ModalAdd modal={modalAdd} toggle={toggleAdd} isEdit={isEdit} denunciateItem={denunciateItem} />
        </>
    )
}

UserDenunciate.propTypes = {
    customers: PropTypes.array,
    total: PropTypes.number,
    onGetTopics: PropTypes.func,
    onAddNewCustomer: PropTypes.func,
    onDeleteCustomer: PropTypes.func,
    onUpdateCustomer: PropTypes.func,
}

export default UserDenunciate
