import {post, get, del} from './index';

async function getAllTopics() {
   return await get('/gerenciador/forum/get/topics');
}

async function getSearchTopics(objPost) {
   return await post('/gerenciador/forum/search/topics', objPost);
}

async function getTopicSingle(objPost) {
   return await post('/gerenciador/forum/get/topic/single', objPost);
}

async function getTopicComments(objPost) {
   return await post('/gerenciador/forum/comments/from/topic', objPost);
}

async function getTotalTopics() {
   return await get('/gerenciador/forum/get/total/topics');
}

async function getAllDenunciate(objPost) {
   return await get('/get/all/denunciate', objPost);
}

async function getSearchDenunciate(objPost) {
   return await post('/get/search/denunciate', objPost);
}

async function addTopic(objAdd) {
   return await post('/gerenciador/forum/add/topics', objAdd);
}

async function editTopic(objUpdate) {
   return await post('/gerenciador/forum/edit/topics', objUpdate);
}

async function setupCategories(objAdd) {
   return await post('/gerenciador/forum/setup/categories/topics', objAdd);
}

async function addMediasTopic(objAdd) {
   return await post('/gerenciador/forum/add/images/topics', objAdd);
}

async function deleteMediasTopic(objAdd) {
   return await post('/gerenciador/forum/delete/images/topics', objAdd);
}

async function deleteTopic(objAdd) {
   return await del('/gerenciador/forum/delete/topic', objAdd);
}

async function deleteComment(objDelete) {
   return await del('/gerenciador/forum/delete/comment', objDelete);
}

async function addComment(objPost) {
   return await post('/gerenciador/forum/add/comment', objPost);
}

async function editComment(objPost) {
   return await post('/gerenciador/forum/edit/comment', objPost);
}

async function disapproveAction(objDelete) {
   return await post('/reprove/denunciate', objDelete);
}

async function approveAction(objDelete) {
   return await post('/approve/denunciate', objDelete);
}

export {
   getAllTopics,
   getTopicSingle,
   getTotalTopics,
   addTopic,
   setupCategories,
   addMediasTopic,
   deleteMediasTopic,
   editTopic,
   deleteTopic,
   getTopicComments,
   deleteComment,
   getAllDenunciate,
   approveAction,
   disapproveAction,
   getSearchDenunciate,
   getSearchTopics,
   addComment,
   editComment
}