import React, { Fragment, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { 
    Container,
    Card,
    CardBody,
    Col,
    Row,
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";

import ModalAdd from "./_components/ModalAdd";
import TableComponent from "./_components/TableComponent";

import { 
    getPosts as onGetBlogPosts 
} from "../../store/posts/actions";

import { deleteBlogPosts } from "../../api/blogPosts";

import { useDispatch, useSelector } from "react-redux";

const BlogPosts = () => {
    const dispatch = useDispatch();

    const { posts } = useSelector(state => ({
        posts: state.posts.posts
    }));

    const [isEdit, setIsEdit] = useState(false);
    const [postItem, setPostItem] = useState({
        title: "", 
        isPublished: false,
        publishDate: "",
        content: ""
    });
    const [modalAdd, setModalAdd] = useState(false);

    useEffect(()=>{
        if(posts && !posts.length){
            dispatch(onGetBlogPosts());
        }
    }, [dispatch, posts])

    const formatDate = rawDate => {
        if(rawDate){
            let date = rawDate.split("-").reverse();
            return `${date[0]}/${date[1]}/${date[2]}`
        }else{
            return "";
        }
    }

    const checkPublished = rawPublished => {
        if(rawPublished){
            return "Sim";
        }else{
            return "Não";
        }
    }

    //pagination customization
    const pageOptions = {
        sizePerPage: 10,
        totalSize: posts && posts.length,
        custom: true,
    };

    const goModalAdd = (isEdit, item) => {
        if(isEdit){
            setPostItem(item);
        }
        setIsEdit(isEdit);
        toggle();
    }

    const handleDeletePost = async post => {
        if(confirm("Tem certeza de que deseja excluir?")){
            await deleteBlogPosts({_id: post._id})
            dispatch(onGetBlogPosts());
        }
    }

    const EcommerceCustomerColumns = [
        {
            text: "id",
            dataField: "_id",
            sort: true,
            hidden: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => <>{row.id}</>,
        },
        {
            text: "Título",
            datafield: "title",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a style={{color: "inherit"}}>
                    <p className="mb-0">{row.title}</p>
                </a>
            )
        },
        {
            text: "Publicado?",
            datafield: "isPublished",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a style={{color: "inherit"}}>
                    <p className="mb-0">{checkPublished(row.isPublished)}</p>
                </a>
            )
        },
        {
            text: "Data de Publicação",
            datafield: "publishDate",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, row) => (
                <a style={{color: "inherit"}}>
                    <p className="mb-0">{formatDate(row.publishDate)}</p>
                </a>
            )
        },
        {
            text: "Ações",
            datafield: "menu",
            isDummyField: true,
            // eslint-disable-next-line react/display-name
            formatter: (cellContent, item) => (
                <UncontrolledDropdown direction="left">
                   <DropdownToggle href="#" className="card-drop" tag="i">
                      <i className="mdi mdi-dots-horizontal font-size-18" />
                   </DropdownToggle>
                   <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem
                        onClick={()=>goModalAdd(true, item)}
                      >
                         <i className="fas fa-pencil-alt text-success me-1" />
                         Editar
                      </DropdownItem>
                      <DropdownItem
                         href="#"
                         onClick={() => handleDeletePost(item)}
                      >
                         <i className="fas fa-trash-alt text-danger me-1" />
                         Excluir
                      </DropdownItem>
                   </DropdownMenu>
                </UncontrolledDropdown>
            )
        }
    ];

    const toggle = () => {
        setModalAdd(!modalAdd);
    };

    const defaultSorted = [
        {
           dataField: "title",
           order: "asc",
        },
    ];

    return (
        <>
            <Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Posts | Suore</title>
                    </MetaTags>
                    <Container fluid>
                        <h3>Posts</h3>
                        <Row className="mb-2">
                            <Col sm="4"></Col>
                            <Col sm="8">
                                <div className="text-sm-end">
                                    <Button
                                        type="button"
                                        color="success"
                                        className="btn-rounded mb-2 me-2"
                                        onClick={() => goModalAdd(false, null)}
                                    >
                                        <i className="mdi mdi-plus me-1"></i>
                                        Adicionar Post
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        {posts && posts.length > 0 &&<TableComponent 
                                            EcommerceCustomerColumns={EcommerceCustomerColumns} 
                                            pageOptions={pageOptions} 
                                            data={posts} 
                                            defaultSorted={defaultSorted}
                                            toggle={toggle}
                                            handleTableChange={console.log}
                                        />}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Fragment>
            <ModalAdd modal={modalAdd} isEdit={isEdit} toggle={toggle} postItem={postItem}/>
        </>
    );
}

export default BlogPosts;