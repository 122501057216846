import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import {
   Button,
   Col,
   Container,
   Row,
   Card,
   CardBody,
   Media,
} from "reactstrap";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import ModalImg from "./_components/ModalFoto"

import {
   getTopicDetail as onGetTopicDetail,
   getTopicComments as onGetTopicComments,
   getIsLoadingComments as onIsLoadingComments
} from "store/community/actions";

import { useSelector, useDispatch } from "react-redux";
import { deleteComment, deleteTopic } from "api/community";
import ImagesSquare from "./_components/ImagesSquare";
import { handleValidDate } from "utils";
import Loading from "components/Loading";
import ModalAddComment from "./_components/ModalAddComment";
import { authorsArray } from "constants/general";

function EditForum(props) {
   const dispatch = useDispatch();
   const _idTopic = props.match.params.id;

   console.log('_idTopic', _idTopic);
   const { isLoading } = useSelector(state => ({
      isLoading: state.Community.isLoading,
   }))

   const { topicDetail } = useSelector(state => ({
      topicDetail: state.Community.topicDetail,
   }))

   const { topicComments } = useSelector(state => ({
      topicComments: state.Community.topicComments,
   }))

   const { totalPageComments } = useSelector(state => ({
      totalPageComments: state.Community.totalPageComments,
   }))

   const { pageComment } = useSelector(state => ({
      pageComment: state.Community.pageComment,
   }))

   const [modalFoto, setModalFoto] = useState(false);
   const [modalAddComment, setModalAddComment] = useState(false);
   const [isEdit, setIsEdit] = useState(null);
   const [fotoSelected, setFotoSelected] = useState(null);
   const [isLoadingComments, setIsLoadingComments] = useState(false);
   const [pageLocal, setPageLocal] = useState(pageComment);

   useEffect(() => {
      console.log('topicComments', topicComments);
   }, [topicComments]);

   useEffect(() => {
      setIsLoadingComments(isLoading)
   }, [isLoading]);

   useEffect(() => {
      dispatch(onGetTopicDetail(_idTopic));
   }, []);

   const toggleFoto = () => {
      setModalFoto(!modalFoto)
   }

   function goModalFoto(item) {
      setFotoSelected(item);
      setModalFoto(modalFoto);
      toggleFoto();
   }

   const renderCategories = () => {
      const arrCategories = topicDetail?.categories.map(item => item.name);
      return arrCategories.join(",")
   }

   function refreshTopicsData() {
      dispatch(onGetTopicDetail(_idTopic))
   }

   const handleDeleteTopic = async (topic) => {
      if (confirm("Tem certeza que deseja excluir?") == true) {
         await deleteTopic({ _idTopic: topic._id });
         refreshTopicsData()
      }
   }

   const handleDeleteComment = async (comment) => {
      if (confirm("Tem certeza que deseja excluir o comentário?") == true) {
         await deleteComment({ _idComment: comment._id });
         refreshTopicsData()
      }
   }

   const changePageComments = (page) => {
      setPageLocal(page);
      onGetTopicComments({
         idEntity: _idTopic,
         from: "forum",
         page: page
      })
   }

   const renderLinkPages = (totalPageComments) => {
      const arrElementsReturn = [];
      for (let index = 1; index <= totalPageComments; index++) {
         arrElementsReturn.push(
            <li
               className={`page-item ${pageLocal === index && 'active'}`}>
               <a
                  onClick={() => changePageComments(index)}
                  href={() => null}
                  className="page-link">
                  {index}
               </a>
            </li>
         )
      }
      return arrElementsReturn;
   }

   const openEditModal = (comment) => {
      setIsEdit(comment)
      setModalAddComment(true)
   }
   
   const openAddModal = (comment) => {
      setIsEdit(null)
      setModalAddComment(true)
   }

   return (
      <>
         <React.Fragment>
            <div className="page-content">
               <MetaTags>
                  <title>Editar Tópico | Suore</title>
               </MetaTags>
               <Container fluid>
                  <Breadcrumb title="Forum" linkTitle="/comunidade/forum" breadcrumbItem="Editar" />
                  <Row>
                     <Col xs="12" md="7">
                        <Card>
                           <CardBody>
                              <div className="text-muted d-flex justify-content-between align-items-center" style={{ marginBottom: '60px' }}>
                                 <h5>Comentários</h5>
                                 <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded btn-delete-comment"
                                    onClick={() => openAddModal()}
                                 >
                                    Comentar
                                 </Button>
                              </div>
                              <div>
                                 {isLoadingComments && <Loading />}
                                 {topicComments && topicComments.length <= 0 && !isLoadingComments && <p>Nenhuma comentário até o momento</p>}
                                 {topicComments && topicComments.length > 0 && !isLoadingComments && (
                                    topicComments.map((comment, idx) => {
                                       const isAdmComment = authorsArray.filter((item) => item.value === comment.users_idusers._id)
                                       return (
                                          <div key={`comment-${comment._id}`} className="container-comment">
                                             <div className="row-comment">
                                                <div className="image">
                                                   {comment.avatar && <img src={comment.avatar} className="avatar" />}
                                                </div>
                                                <div className="info-comment">
                                                   <small>{handleValidDate(comment.created_at)}</small>
                                                   <h4>{comment.users_idusers.nickname || comment.users_idusers.name}</h4>
                                                   <p>{comment.comment}</p>
                                                </div>
                                                <div className="box-actions-comment">
                                                   {isAdmComment.length > 0 &&
                                                      <Button
                                                         type="button"
                                                         color="primary"
                                                         className="btn-rounded btn-edit-comment"
                                                         onClick={() => openEditModal(comment)}
                                                      >
                                                         Editar
                                                      </Button>
                                                   }

                                                   <Button
                                                      type="button"
                                                      color="danger"
                                                      className="btn-rounded btn-delete-comment"
                                                      onClick={() => handleDeleteComment(comment)}
                                                   >
                                                      Deletar Comentário
                                                   </Button>
                                                </div>
                                             </div>
                                             {
                                                comment.commentsReply.map((commentReply) => {
                                                   return (
                                                      <div key={`commentReply-${commentReply._id}`} className="row-comment row-comment-reply">
                                                         <div className="image">
                                                            {commentReply.avatar && <img src={commentReply.avatar} className="avatar" />}
                                                         </div>
                                                         <div className="info-comment">
                                                            <small>{handleValidDate(commentReply.created_at)}</small>
                                                            <h4>{commentReply.users_idusers.nickname || commentReply.users_idusers.name}</h4>
                                                            <p>{commentReply.comment}</p>
                                                         </div>
                                                         <Button
                                                            type="button"
                                                            color="danger"
                                                            className="btn-rounded btn-delete-comment"
                                                            onClick={() => handleDeleteComment(comment)}
                                                         >
                                                            Deletar Resposta
                                                         </Button>
                                                      </div>
                                                   );
                                                })
                                             }
                                          </div>
                                       );
                                    })
                                 )}

                                 {console.log('totalPageComments', totalPageComments)}
                                 {totalPageComments > 0 && (
                                    <ul className="list-page pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination col">
                                       {renderLinkPages(totalPageComments)}
                                    </ul>
                                 )}
                              </div>
                           </CardBody>
                        </Card>
                     </Col>
                     <Col xs="12" md="5">
                        <Card>
                           <CardBody>
                              <Media>
                                 <Media body className="align-self-center">
                                    <div className="text-muted">
                                       <h5>{topicDetail && topicDetail.title}</h5>
                                       <br />
                                       <p><strong>Autor:</strong><br />{topicDetail?.user && topicDetail?.user[0]?.email}</p>
                                       <p><strong>Categorias:</strong><br />{topicDetail?.categories && renderCategories()}</p>
                                       <p><strong>Texto:</strong><br />{topicDetail && topicDetail.text}</p>
                                    </div>
                                 </Media>
                              </Media>

                              <Button
                                 style={{ marginTop: '26px' }}
                                 type="button"
                                 color="danger"
                                 className="btn-rounded mb-2 me-2"
                                 onClick={handleDeleteTopic}
                              >
                                 Deletar Tópico
                              </Button>
                           </CardBody>
                        </Card>
                        <Card>
                           <CardBody>
                              <div className="mb-2 text-muted d-flex justify-content-between align-items-center">
                                 <h5>Imagens</h5>
                              </div>
                              <ImagesSquare medias={topicDetail?.media} onGoModalFoto={goModalFoto} />
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>
            </div>
         </React.Fragment>
         <ModalImg modalFoto={modalFoto} toggleFoto={toggleFoto} fotoSelected={fotoSelected} />
         <ModalAddComment
            modal={modalAddComment}
            onClose={() => setModalAddComment(false)}
            idTopic={topicDetail._id}
            isEdit={isEdit}
         />
      </>
   );
}

EditForum.propTypes = {
   match: PropTypes.object,
   onAddNewCustomer: PropTypes.func,
   onDeleteCustomer: PropTypes.func,
   onUpdateCustomer: PropTypes.func,
}

export default EditForum;