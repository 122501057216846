import {
   GET_SUBSCRIBERS_SEARCH_CLEAN,
   GET_SUBSCRIBERS_SEARCH,
   GET_SUBSCRIBERS_SUCCESS,
   GET_SUBSCRIBERS_FAIL,
   GET_SUBSCRIBERS,
   IS_LOADING_SUBSCRIBERS,
   IS_LOADED_SUBSCRIBERS
} from "./actionTypes"

export const getSubscribers = payload => ({
   type: GET_SUBSCRIBERS,
   payload: payload
})

export const getSubscribersSuccess = subscribers => ({
   type: GET_SUBSCRIBERS_SUCCESS,
   payload: subscribers,
})


export const getSubscribersFail = error => ({
   type: GET_SUBSCRIBERS_FAIL,
   payload: error,
})

export const getSubscribersSearch = search => ({
   type: GET_SUBSCRIBERS_SEARCH,
   search
})

export const getSubscribeSearchClean = () => ({
   type: GET_SUBSCRIBERS_SEARCH_CLEAN
})

export const isLoadingSubscribers = () => ({
   type: IS_LOADING_SUBSCRIBERS
})

export const isLoadedSubscribers = () => ({
   type: IS_LOADED_SUBSCRIBERS
})