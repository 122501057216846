/* SUBSCRIBERS */
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS"
export const GET_SUBSCRIBERS_SUCCESS = "GET_SUBSCRIBERS_SUCCESS"
export const GET_SUBSCRIBERS_FAIL = "GET_SUBSCRIBERS_FAIL"
export const IS_LOADING_SUBSCRIBERS = "IS_LOADING_SUBSCRIBERS"
export const IS_LOADED_SUBSCRIBERS = "IS_LOADED_SUBSCRIBERS"

/* SUBSCRIBERS SEARCH*/
export const GET_SUBSCRIBERS_SEARCH = "GET_SUBSCRIBERS_SEARCH"
export const GET_SUBSCRIBERS_SEARCH_SUCCESS = "GET_SUBSCRIBERS_SEARCH_SUCCESS"
export const GET_SUBSCRIBERS_SEARCH_FAIL = "GET_SUBSCRIBERS_SEARCH_FAIL"
export const GET_SUBSCRIBERS_SEARCH_CLEAN = "GET_SUBSCRIBERS_SEARCH_CLEAN"
