import { call, put, takeEvery } from "redux-saga/effects"

// Ecommerce Redux States
import {
   GET_TOPICS,
   GET_TOTAL_TOPICS,
   GET_TOPIC_SINGLE,
   GET_TOPIC_COMMENTS,
   GET_DENUNCIATE,
   GET_DENUNCIATE_SEARCH,
   GET_TOPICS_SEARCH
} from "./actionTypes"
import {
   getTopicsFail,
   getTopicsSuccess,
   getTotalTopicsSuccess,
   getTotalTopicsFail,
   getTopicDetailSuccess,
   getTopicDetailFail,
   getTopicCommentsSuccess,
   getTopicCommentsTotalPage,
   getTopicCommentsCurrentPage,
   getIsLoadingComments,
   getIsLoadingDenunciate,
   getDenunciateSuccess,
   getDenunciateSearchSuccess,
   getTopicsSearchSuccess
} from "./actions"

import {
   getAllTopics,
   getTotalTopics,
   getTopicSingle,
   getTopicComments,
   getAllDenunciate,
   getSearchDenunciate,
   getSearchTopics
} from '../../api/community';

function* fetchTopics() {
   try {
      const response = yield call(getAllTopics);
      yield put(getTopicsSuccess(response.data.topics));
   } catch (error) {
      yield put(getTopicsFail(error));
   }
}

function* fetchTopicsSearch({ _objFilter }) {
   try {
      const response = yield call(getSearchTopics, _objFilter);
      yield put(getTopicsSearchSuccess(response.data.topics));
   } catch (error) {
      yield put(getTopicsFail(error));
   }
}

function* fetchTotalTopics() {
   try {
      const response = yield call(getTotalTopics);
      yield put(getTotalTopicsSuccess(response.data.total));
   } catch (error) {
      yield put(getTotalTopicsFail(error));
   }
}

function* fetchTopicSingle({ _idTopic }) {
   try {
      yield put(getIsLoadingComments(true));
      const response = yield call(getTopicSingle, {_idTopic});
      yield put(getTopicDetailSuccess(response.data.topics));
      
      const respComments = yield call(getTopicComments, {
         idEntity: _idTopic,
         from: "forum",
         page: 1
      });
      yield put(getTopicCommentsSuccess(respComments.data.comments));
      yield put(getTopicCommentsTotalPage(respComments.data.totalPage));
      yield put(getTopicCommentsCurrentPage(respComments.data.page));
      yield put(getIsLoadingComments(false));
   } catch (error) {
      yield put(getTopicDetailFail(error));
   }
}

function* fetchTopicComments({ _objFilterComments }) {
   try {
      yield put(getIsLoadingComments(true));
      const respComments = yield call(getTopicComments, _objFilterComments);
      yield put(getTopicCommentsSuccess(respComments.data.comments));
      yield put(getIsLoadingComments(false));
   } catch (error) {
      yield put(getTopicDetailFail(error));
   }
}

function* fetchDenunciate({ _objFilterDenunciate }) {
   try {
      yield put(getIsLoadingDenunciate(true));
      const respDenunciate = yield call(getAllDenunciate);
      yield put(getDenunciateSuccess(respDenunciate.data.denunciate));
      yield put(getIsLoadingDenunciate(false));
   } catch (error) {
      yield put(getTopicDetailFail(error));
   }
}

function* fetchDenunciateSearch({ _objFilterDenunciate }) {
   try {
      yield put(getIsLoadingDenunciate(true));
      const respDenunciate = yield call(getSearchDenunciate, _objFilterDenunciate);
      yield put(getDenunciateSearchSuccess(respDenunciate.data.denunciate));
      yield put(getIsLoadingDenunciate(false));
   } catch (error) {
      yield put(getTopicDetailFail(error));
   }
}

function* usersSaga() {
   yield takeEvery(GET_TOTAL_TOPICS, fetchTotalTopics);
   yield takeEvery(GET_TOPICS, fetchTopics);
   yield takeEvery(GET_TOPIC_SINGLE, fetchTopicSingle);
   yield takeEvery(GET_TOPIC_COMMENTS, fetchTopicComments);
   yield takeEvery(GET_DENUNCIATE, fetchDenunciate);
   yield takeEvery(GET_DENUNCIATE_SEARCH, fetchDenunciateSearch);
   yield takeEvery(GET_TOPICS_SEARCH, fetchTopicsSearch);
}

export default usersSaga
