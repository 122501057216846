import { call, put, takeEvery } from "redux-saga/effects"

import { GET_POSTS } from "./actionTypes";
import { getPostsSuccess, getPostsFail } from "./actions";

import { 
    getBlogPosts 
} from "../../api/blogPosts";

function* fetchPosts() {
    try{
        const response = yield call(getBlogPosts);
        yield put(getPostsSuccess(response.data.posts));
    }catch(err){
        yield put(getPostsFail(err));
    }
}

function* tasksSaga() { 
    yield takeEvery(GET_POSTS, fetchPosts);
}

export default tasksSaga;