import React, { useEffect, useRef, useState } from "react";
import {
   Col,
   Row,
} from "reactstrap";

import {
   getTopics as onGetTopics,
} from "store/community/actions";

import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Loading from "components/Loading";
import Switch from "react-switch";
import Select from "react-select";
import { addTopic, editTopic } from "api/community";
import makeAnimated from 'react-select/animated';
import { Editor } from '@tinymce/tinymce-react';

const animatedComponents = makeAnimated();

const authorsArray = [
   {
      label: 'Fernanda Queiroz',
      value: "612ee7627be6a761277a940a"
   },
   {
      label: 'Juliana de Oliveira',
      value: "6193bf4c9053cb3de1cf98ee"
   },
   {
      label: 'Raissa',
      value: "618ebc3f3331f41e2fe4d66e"
   },
   {
      label: 'Joice Campos',
      value: "612ee7647be6a761277abc98"
   },
   {
      label: 'Karine',
      value: "612ee76f7be6a761277bc680"
   },
   {
      label: 'Lyka Ribeiro',
      value: "612ee76f7be6a761277bc627"
   },
]

const FormGeneralInfo = ({ isEdit, toggle, topicItem }) => {
   const { register, handleSubmit, reset, formState: { errors } } = useForm();
   const dispatch = useDispatch();

   const editorRef = useRef(null);

   const [isEditLocal, setIsEditLocal] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [initialTextValue, setInitialTextValue] = useState(topicItem?.text);


   console.log('topicItem',topicItem);
   const [checkedIsAdm, setCheckedIsAdm] = useState(topicItem?.isAdm ? topicItem?.isAdm : true)
   const [checkedIsFixed, setCheckedIsFixed] = useState(topicItem?.isFixed || true)
   const [checkedIsPublished, setCheckedIsPublished] = useState(topicItem?.is_published || true)

   const [selectedAuthor, setSelectedAuthor] = useState(null);

   const findAuthor = () => {
      const author = authorsArray.filter((item, index) => item.value === topicItem.user[0]._id && item)
      return author
   }

   useEffect(() => {
      setIsEditLocal(isEdit);
      const title = topicItem?.title;
      const text = topicItem?.text;

      const isAdmEdit = isEdit ? topicItem?.isAdm : true
      const isFixedEdit = isEdit ? topicItem?.isFixed : true
      const isPublishedEdit = isEdit ? topicItem?.is_published : true
      setCheckedIsAdm(isAdmEdit)
      setCheckedIsFixed(isFixedEdit)
      setCheckedIsPublished(isPublishedEdit)

      const authorEdit = isEdit && !topicItem?.isAdm ? findAuthor() : null
      setSelectedAuthor(authorEdit)

      reset({
         title: isEdit ? title : '',
         text: isEdit ? text : '',
      });

      setInitialTextValue(isEdit ? topicItem?.text : '');

   }, [isEdit, topicItem]);

   /**
    * Handling submit customer on customer form
    */
   const handleValidCustomerSubmit = async (data) => {
      console.log('data', data);

      if (editorRef.current && !editorRef.current.getContent()) {
         return;
      }

      if (data.title) {
         const titleFinal = data.title;
         const textFinal = editorRef.current.getContent();
         const author = checkedIsAdm ? "6244b8f76447836b233960ef" : selectedAuthor[0].value;

         const objAdd = {
            title: titleFinal,
            text: textFinal,
            isAdm: checkedIsAdm,
            isFixed: checkedIsFixed,
            is_published: checkedIsPublished,
            users_usersid: author
         }

         setIsLoading(true);
         if (!isEdit) {
            await addTopic(objAdd);
         } else {
            objAdd._id = topicItem._id;
            await editTopic(objAdd);
         }

         setIsLoading(false);
         dispatch(onGetTopics());
         toggle();
      }
   }

   return (
      <>
         <form
            onSubmit={handleSubmit(handleValidCustomerSubmit)}
         >
            <Row form>
               <Col className="col-12">
                  <div className="mb-3">
                     <div className="form-group">
                        <label htmlFor="title" className="">Título</label>
                        <input {...register("title", { required: true })} className="is-touched is-pristine av-valid form-control" required />
                        {errors.title && <div className="alert alert-danger">Campo obrigatório</div>}
                     </div>
                  </div>

                  <div className="mb-3 d-flex align-items-center">
                     <label htmlFor="control-label" style={{ marginRight: "20px", marginBottom: "0" }}>Post do Admin?</label>
                     <Switch
                        checked={checkedIsAdm}
                        onChange={setCheckedIsAdm}
                        height={20}
                        onColor="#556ee6"
                     />
                  </div>

                  {!checkedIsAdm && <div className="mb-3 templating-select select2-container">
                     <label htmlFor="control-label">De quem será o post?</label>
                     <Select
                        value={selectedAuthor}
                        isMulti={false}
                        onChange={(selected) => setSelectedAuthor([selected])}
                        options={authorsArray}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                     />
                  </div>}

                  <div className="mb-3 d-flex align-items-center">
                     <label htmlFor="control-label" style={{ marginRight: "20px", marginBottom: "0" }}>Fixo no topo?</label>
                     <Switch
                        checked={checkedIsFixed}
                        onChange={setCheckedIsFixed}
                        height={20}
                        onColor="#556ee6"
                     />
                  </div>

                  <div className="mb-3 d-flex align-items-center">
                     <label htmlFor="control-label" style={{ marginRight: "20px", marginBottom: "0" }}>Publicado?</label>
                     <Switch
                        checked={checkedIsPublished}
                        onChange={setCheckedIsPublished}
                        height={20}
                        onColor="#556ee6"
                     />
                  </div>

                  <div className="mb-3">
                     <div className="form-group">
                        <label htmlFor="text" className="">Texto</label>
                        <Editor
                           apiKey="8ioqytzwgdiq5kh99u26h5w3vwc306k713okkf806f7hbhv5"
                           onInit={(evt, editor) => editorRef.current = editor}
                           initialValue={initialTextValue}
                           className="is-touched is-pristine av-valid form-control"
                           init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                 'advlist autolink lists link image charmap print preview anchor',
                                 'searchreplace visualblocks code fullscreen',
                                 'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                 'bold italic backcolor | alignleft aligncenter ' +
                                 'alignright alignjustify | bullist numlist outdent indent | ' +
                                 'removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color:#fff }'
                           }}
                        />
                        {/* <textarea {...register("text", { required: true })} className="is-touched is-pristine av-valid form-control" required /> */}
                        {errors.text && <div className="alert alert-danger">Campo obrigatório</div>}
                     </div>
                  </div>
               </Col>
            </Row>
            <Row className="mt-4">
               <Col>
                  <div className="text-end">
                     {!isLoading && <button
                        type="submit"
                        className="btn btn-success save-customer"
                     >
                        Salvar
                     </button>}
                     {isLoading && <Loading />}
                  </div>
               </Col>
            </Row>
         </form>
      </>
   );
}

FormGeneralInfo.propTypes = {
   toggle: PropTypes.func,
   isEdit: PropTypes.bool,
   topicItem: PropTypes.object,
}

export default FormGeneralInfo;